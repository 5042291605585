import ROLES from 'src/common/enums/roles';
import { ICONS } from 'src/common/Icons';

export const getSidebarRoutes = () => {
  return [
    {
      key: '/tracker',
      icon: ICONS.timer,
      label: 'Time tracker',
      roles: ROLES.all(),
    },
    {
      label: 'Analyze',
      type: 'group',
      roles: ROLES.all(),
      children: [
        {
          key: '/dashboard',
          icon: ICONS.dashboard,
          label: 'Dashboard',
          roles: ROLES.all(),
        },
      ],
    },
    {
      label: 'Manage',
      type: 'group',
      roles: ROLES.all(),
      children: [
        {
          key: '/clients',
          icon: ICONS.userSwitch,
          label: 'Clients',
          roles: [ROLES.Superadmin, ROLES.Admin],
        },
        {
          key: '/projects',
          icon: ICONS.projects,
          label: 'Projects',
          roles: ROLES.all(),
        },
        {
          key: '/tags',
          icon: ICONS.tag,
          label: 'Tags',
          roles: [ROLES.Superadmin, ROLES.Admin],
        },
        {
          key: '/licenses',
          icon: ICONS.projects,
          label: 'Licenses',
          roles: [ROLES.Superadmin, ROLES.Admin],
        },
      ],
    },
    {
      label: 'Administration',
      type: 'group',
      roles: ROLES.all(),
      children: [
        {
          key: '/users',
          icon: ICONS.userAdd,
          label: 'Users',
          roles: ROLES.all(),
        },
        {
          key: '/kpi/users',
          icon: ICONS.userGroupAdd,
          label: 'KPIs',
          roles: ROLES.all(),
        },
        {
          key: '/kpi/templates',
          icon: ICONS.userGroupAdd,
          label: 'KPIs Templates',
          roles: [ROLES.Superadmin, ROLES.Admin],
        },
      ],
    },
  ];
};
