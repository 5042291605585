import { Select, Tag, Input } from 'antd';
import Utils from 'src/common/Utils';
import KpiService from 'src/common/api/KpiService';
import { KpiTypes } from 'src/common/enums/kpi';
import { kpiTypes } from 'src/common/constants/kpi';
import GradeSwitch from 'src/components/buttons/GradeSwitch';
import MainButton from 'src/components/buttons/MainButton';
import ROLES from 'src/common/enums/roles';

const { Option } = Select;

export const tableColumns = (roleId) => [
  {
    title: 'Name',
    dataIndex: ['kpi_template', 'title'],
    key: ['kpi_template', 'title'],
  },
  {
    title: 'Type',
    dataIndex: ['kpi_template', 'kpi_type'],
    key: ['kpi_template', 'kpi_type'],
    responsive: ['sm'],
    render: (_, { kpi_template }) => {
      switch (kpi_template?.kpi_type) {
        case KpiTypes.PERSONAL:
          return <Tag color='cyan'>Personal</Tag>;
        case KpiTypes.TEAM:
          return <Tag color='red'>Team</Tag>;
        case KpiTypes.BONUS:
          return <Tag color='green'>Bonus</Tag>;
        case KpiTypes.ORGANIZATIONAL:
          return <Tag color='volcano'>Organizational</Tag>;
      }
    },
  },
  {
    title: 'Expected grade',
    dataIndex: ['kpi_template', 'expected_grade'],
    key: ['kpi_template', 'expected_grade'],
    responsive: ['sm'],
  },
  {
    title: 'Grade',
    dataIndex: 'grade',
    key: 'grade',
    responsive: ['sm'],
    render: (_, { id, user_id, kpi_template_id, quarter, year, grade }) => {
      return (
        <Input
          id='grade'
          onChange={(val) => {
            const grade = val.target.value;
            const regex = /^\d+(\.\d+)?$/;

            if (
              (regex.test(grade) &&
                parseFloat(grade) > 0 &&
                parseFloat(grade) <= 6) ||
              grade === ''
            ) {
              KpiService.update(id, {
                user_id,
                kpi_template_id,
                quarter,
                year,
                grade,
              });
            }
          }}
          defaultValue={grade ? grade : null}
          className='w-16 text-center bg-background'
          disabled={![ROLES.Superadmin, ROLES.Admin].includes(roleId)}
        />
      );
    },
  },
  {
    title: 'Completed',
    dataIndex: 'completed',
    key: 'completed',
    responsive: ['sm'],
    render: (_, { id, completed }) => {
      return (
        <GradeSwitch
          id={id}
          initialChecked={completed}
          disabled={![ROLES.Superadmin, ROLES.Admin].includes(roleId)}
        />
      );
    },
  },
];

const filterData = {
  year: Utils.generateYearObjects(2023),
};

const quarters = [
  { label: 'Q1' },
  { label: 'Q2' },
  { label: 'Q3' },
  { label: 'Q4' },
];

export const filterFields = [
  {
    controlType: (
      <Select
        mode='single'
        maxTagCount='responsive'
        optionFilterProp='children'
        showSearch={true}
        defaultValue={String(new Date().getFullYear())}
      >
        {filterData.year?.map((option, index) => {
          return (
            <Option
              key={index}
              value={option.year}
            >
              {option.year}
            </Option>
          );
        })}
      </Select>
    ),
    colSize: 'col-span-12 sm:col-span-3',
    options: {
      label: 'Year',
      name: 'year',
      value: null,
    },
  },
];

export const mainFieldsModal = () => {
  return [
    {
      controlType: (
        <Select>
          {quarters.map((item, index) => {
            return (
              <Option
                key={index}
                value={item.label}
              >
                {item.label}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Quarter',
        name: 'quarter',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the quarter',
          },
        ],
      },
    },
    {
      controlType: (
        <Select>
          {Utils.generateYearObjects(2023).map((year, index) => {
            return (
              <Option
                key={index}
                value={year.year}
              >
                {year.year}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Year',
        name: 'year',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the year',
          },
        ],
      },
    },
    {
      controlType: (
        <MainButton
          type='primary'
          htmlType='submit'
          label={'Create KPI'}
        />
      ),
      colSize: 'col-span-12 sm:col-span-6',
    },
  ];
};

export const mainFields = (headerTitle) => {
  return [
    ...Utils.createHeader({ title: headerTitle }),
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-8',
      options: {
        label: 'Title',
        name: 'title',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the name of the KPI',
          },
          () => ({
            validator(_, value) {
              if (value?.length > 190) {
                return Promise.reject('Title is too long');
              }
              return Promise.resolve();
            },
          }),
        ],
      },
    },
    {
      controlType: (
        <Select>
          {kpiTypes.map((type) => {
            return (
              <Option
                key={type.value}
                value={type.value}
              >
                {type.label}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'KPI type',
        name: 'kpi_type',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the KPI type',
          },
        ],
      },
    },
    {
      controlType: <Input.TextArea />,
      colSize: 'col-span-12 sm:col-span-8',
      options: {
        label: 'Description',
        name: 'description',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the KPI description',
          },
          () => ({
            validator(_, value) {
              if (value > 190) {
                return Promise.reject('Description is too long');
              }
              return Promise.resolve();
            },
          }),
        ],
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Expected grade',
        name: 'expected_grade',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the KPI expected grade',
            pattern: new RegExp(/^[0-9]+$/),
          },
          () => ({
            validator(_, value) {
              if (value > 6) {
                return Promise.reject('Grade cannot be larger than 6');
              }
              return Promise.resolve();
            },
          }),
        ],
      },
    },
    {
      controlType: (
        <Select>
          {quarters.map((item, index) => {
            return (
              <Option
                key={index}
                value={item.label}
              >
                {item.label}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Quarter',
        name: 'quarter',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the quarter',
          },
        ],
      },
    },
    {
      controlType: (
        <Select>
          {Utils.generateYearObjects(2023).map((year, index) => {
            return (
              <Option
                key={index}
                value={year.year}
              >
                {year.year}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Year',
        name: 'year',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the year',
          },
        ],
      },
    },
  ];
};
