import React, { useEffect, useState } from "react";
import { DatePicker, Input, TimePicker } from "antd";
import { ICONS } from "src/common/Icons";
import TimeRecordsService from "src/common/api/TimeRecordsService";
import { setTrackedTimes } from "src/common/actions/trackedTimes";
import { useDispatch } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";

function TimeRangePicker({ form, setIsWorkFromHome }) {
  let todayDate = dayjs();
  // States for date and time picks
  const [datePick, setDatePick] = useState({
    selectedDate: todayDate,
    startTime: null,
    endTime: null,
  });

  //State for opening and closing calendar picker
  const [todayToggle, setTodayToggle] = useState(false);

  //Time difference state
  const [timeDifference, setTimeDifference] = useState("");
  const [timeDifferenceInput, setTimeDifferenceInput] = useState("");

  const handleCalendarToggle = () => {
    setTodayToggle(!todayToggle);
  };

  const dispatch = useDispatch();
  // Select date from input
  const handleDateChange = (date) => {
    setDatePick({
      ...datePick,
      selectedDate: date,
    });
    handleCalendarToggle();
  };

  // Select Start Time from input
  const handleStartTimeChange = (time) => {
    setDatePick({
      ...datePick,
      startTime: time,
    });
  };

  // Select End Time from input
  const handleEndTimeChange = (time) => {
    setDatePick({
      ...datePick,
      endTime: time,
    });
  };

  // Update redux
  const getAllTrackedTimes = async (page = 1) => {
    let response = await TimeRecordsService.all({ page });
    dispatch(setTrackedTimes(response));
  };

  //Display time difference between start and end times
  useEffect(() => {
    if (datePick.startTime && datePick.endTime) {
      const startTime = datePick.startTime;
      const endTime = datePick.endTime;
      const timeDiff = endTime - startTime;

      const timeDiffFormatted = moment.utc(timeDiff).format("HH:mm");

      // Update the input field when the time difference changes
      setTimeDifferenceInput(timeDiffFormatted);

      setTimeDifference(timeDiffFormatted);
    } else {
      setTimeDifference("00:00");
      setTimeDifferenceInput("00:00"); // Clear the input when there is no time difference
    }
  }, [datePick.startTime, datePick.endTime]);

  // Handle time difference change
  const handleTimeDifferenceChange = (newTimeDifference) => {
    setTimeDifferenceInput(newTimeDifference);
  };

  // Handle time difference input enter key press
  const handleTimeDifferenceEnter = () => {
    if (datePick.startTime && timeDifferenceInput) {
      // Convert the time difference input to a moment duration
      const newTimeDiffDuration = moment.duration(timeDifferenceInput);

      // Calculate the new end time
      const startTime = datePick.startTime;
      const newEndTime = startTime.clone().add(newTimeDiffDuration);

      // Update the state with the new end time
      setDatePick((prev) => ({
        ...prev,
        endTime: newEndTime,
      }));
    }
  };

  // Format the date and time range and send it to the api
  const handleTimeRange = async () => {
    let selectedDateFormatted = null;
    let startTimeFormatted = null;
    let endTimeFormatted = null;

    // Formatting dates
    if (datePick.selectedDate && datePick.startTime && datePick.endTime) {
      selectedDateFormatted = datePick.selectedDate.format("YYYY-MM-DD");
      startTimeFormatted = datePick.startTime.format("HH:mm:ss");
      endTimeFormatted = datePick.endTime.format("HH:mm:ss");
    }

    // Combining the selected date and times
    let combinedStartDateTime = `${selectedDateFormatted} ${startTimeFormatted}`;
    let combinedEndDateTime = `${selectedDateFormatted} ${endTimeFormatted}`;

    // Getting data from the rest of the form
    const data = form.getFieldsValue();

    // Posting to the api
    await TimeRecordsService.create({
      ...data,
      start_time: combinedStartDateTime,
      stop_time: combinedEndDateTime,
    }).then((res) => {
      if (res) {
        form.resetFields();
        //videti zasto resetFields ne resetuje work from home
        form?.setFieldValue("is_work_from_home", false);
        setIsWorkFromHome(false);
      }
    });

    getAllTrackedTimes();
  };

  return (
    <div className="flex flex-row justify-center items-center gap-1">
      <div className="border-l-[1px] border-t-0 border-r-0 border-b-0 border-solid border-slate-200">
        <TimePicker
          onSelect={handleStartTimeChange}
          value={datePick.startTime}
          format="h:mm A"
          hideDisabledOptions
          changeOnBlur
          use12Hours
          placeholder="Start Date"
          className="w-[6.25rem] border-0 hover:border-0"
        />
      </div>
      <span>-</span>
      <div>
        <TimePicker
          onSelect={handleEndTimeChange}
          value={datePick.endTime}
          format="h:mm A"
          hideDisabledOptions
          changeOnBlur
          use12Hours
          placeholder="End Date"
          className="w-[6.25rem] border-0 hover:border-0"
        />
      </div>
      <div className="w-[150px] border-r-[1px] border-t-0 border-l-0 border-b-0 border-solid border-slate-200 relative">
        {todayDate.format("YYYY-MM-DD") ===
          datePick?.selectedDate?.format("YYYY-MM-DD") && (
          <div
            className="bg-white absolute w-[100px] h-full z-40 flex justify-center items-center"
            onClick={handleCalendarToggle}
            onBlur={handleCalendarToggle}
            tabIndex="0"
          >
            Today
          </div>
        )}
        <DatePicker
          className="border-0 hover:border-none focus:shadow-none cursor-pointer icon-datepicker"
          onChange={handleDateChange}
          onBlur={handleCalendarToggle}
          onClick={handleCalendarToggle}
          value={datePick.selectedDate}
          defaultValue={todayDate}
          open={todayToggle}
        />
      </div>
      <div className="w-[70px] mb-0">
        <Input
          value={timeDifferenceInput}
          onChange={(e) => handleTimeDifferenceChange(e.target.value)}
          onBlur={handleTimeDifferenceEnter}
          className="text-center border-0 hover:border-0 text-lg"
        />
      </div>

      <div
        onClick={handleTimeRange}
        className="bg-[#B4FF00] hover:bg-[#132038] hover:text-[#B4FF00] transition-all duration-300 w-[35px] h-[35px] rounded-full flex items-center justify-center cursor-pointer text-lg"
      >
        {ICONS.save}
      </div>
    </div>
  );
}

export default TimeRangePicker;
