import { SET_TAGS } from '../actions/tags';

export default function tags(
  state = {
    tags: null,
  },
  action
) {
  switch (action.type) {
    case SET_TAGS:
      return Object.assign({}, state, {
        tags: action.tags,
      });
    default:
      return state;
  }
}
