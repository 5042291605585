import { Input, Select, Tag } from 'antd';
import ROLES from 'src/common/enums/roles';
import Utils from 'src/common/Utils';
const { Option } = Select;

export const tableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'id',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'id',
    responsive: ['sm'],
  },
  {
    title: 'Role',
    dataIndex: 'role_id',
    key: 'role_id',
    responsive: ['sm'],
    render: (_, { role_id }) => {
      switch (role_id) {
        case ROLES.Superadmin:
          return <Tag color='red'>Superadmin</Tag>;
        case ROLES.Admin:
          return <Tag color='volcano'>Administrator</Tag>;
        case ROLES.Developer:
          return <Tag color='cyan'>Developer</Tag>;
        default:
          return <span>None</span>;
      }
    },
  },
];

const roles = [
  {
    label: 'Administrator',
    value: ROLES.Admin,
  },
  {
    label: 'Developer',
    value: ROLES.Developer,
  },
];

export const filterFields = [
  {
    controlType: <Input allowClear />,
    colSize: 'col-span-12 sm:col-span-3',
    options: {
      label: 'Name',
      name: 'name',
      value: null,
    },
  },
  {
    controlType: (
      <Select allowClear={true}>
        {roles.map((role) => {
          return (
            <Option key={role.value} value={role.value}>
              {role.label}
            </Option>
          );
        })}
      </Select>
    ),
    colSize: 'col-span-12 sm:col-span-3',
    options: {
      label: 'Role',
      name: 'role_id',
      value: null,
    },
  },
];

export const mainFields = ({ headerTitle }) => {
  return [
    ...Utils.createHeader({ title: headerTitle }),
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Name',
        name: 'name',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input user name!',
          },
        ],
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Email',
        name: 'email',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input user email!',
          },
        ],
      },
    },
    {
      controlType: (
        <Select>
          {roles.map((role) => {
            return (
              <Option key={role.value} value={role.value}>
                {role.label}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-12',
      options: {
        label: 'Role',
        name: 'role_id',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input user role!',
          },
        ],
      },
    },
    {
      controlType: <Input.Password />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Password',
        name: 'password',
      },
    },
    {
      controlType: <Input.Password />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Password confirmation',
        name: 'password_confirmation',
      },
    },
  ];
};
