import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Pagination,
  Popconfirm,
  Table as AntTable,
  Tooltip,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import FormFactory from './FormFactory';
import { ICONS } from 'src/common/Icons';
import Utils from 'src/common/Utils';
import MainButton from './buttons/MainButton';

/**
 * Table component with various features such as pagination, filtering, and actions.
 *
 * @param {object} props - The component's props.
 * @param {object[]} props.data - The data to be displayed in the table.
 * @param {object[]} props.columns - The columns configuration for the table.
 * @param {function} props.onEdit - Function for editing a table row.
 * @param {function} props.onDelete - Function for deleting a table row.
 * @param {object[]} props.filterFields - The fields for filtering the table.
 * @param {function} props.onFilter - Function for applying filters.
 * @param {boolean} props.hideActions - Flag to hide action buttons.
 * @returns {JSX.Element} The generated table component.
 */

const Table = ({
  data,
  columns,
  onEdit,
  onDelete,
  filterFields,
  onFilter,
  hideActions,
  hideDelete,
  hideEdit,
  onRowClick,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [query, setQuery] = useState({});

  const dataSource = data?.['data']?.map((item) => {
    return { ...item, key: item.id };
  });

  /**
   * Generates action buttons for the table.
   *
   * @returns {object[]} An array of action button configurations.
   */

  const actionButtons = () => {
    if (hideActions) {
      return [];
    }
    return [
      {
        title: 'Action',
        key: 'delete',
        dataIndex: 'id',
        width: '9rem',
        render: (_, data) => (
          <div>
            {!hideEdit && (
              <Tooltip title='Edit'>
                <Button
                  className='mr-2'
                  onClick={
                    onEdit ? () => onEdit(data) : () => onEditNavigate(data.id)
                  }
                  icon={ICONS.edit}
                />
              </Tooltip>
            )}
            {!hideDelete && (
              <Popconfirm
                title='Are you sure you want to delete?'
                onConfirm={() => onDelete(data)}
                okText='Yes'
                cancelText='No'
                style={{
                  color: 'red',
                }}
              >
                <Tooltip title='Delete'>
                  <Button icon={ICONS.delete} />
                </Tooltip>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ];
  };
  useEffect(() => {
    const formatQuery = Utils.createQueryString(query).replaceAll('FILTER', '');
    onFilter(formatQuery);
  }, [query]);

  const onEditNavigate = (id) => {
    navigate(`edit/${id}`);
  };

  const onFilterTable = (data, filter) => {
    setQuery({ ...query, ...data, page: filter ? 1 : query?.page });
  };

  const onChange = (pagination, filters, sorter, extra) => {
    const orderQuery = sorter?.order
      ? sorter?.order === 'ascend'
        ? 'asc'
        : 'desc'
      : '';

    setQuery({
      ...query,
      sort: sorter?.field,
      order: orderQuery,
    });
  };

  return (
    <div>
      <AntTable
        onRow={(e) => {
          return {
            onClick: () => onRowClick?.(e),
          };
        }}
        rowClassName={onRowClick ? 'cursor-pointer' : ''}
        loading={!data}
        pagination={false}
        columns={[...columns, ...actionButtons()]}
        dataSource={dataSource}
        onChange={onChange}
        showSorterTooltip={{ target: 'sorter-icon' }}
        title={() => {
          if (!filterFields) return null;
          return (
            <FormFactory
              form={form}
              onFinish={(data) => {
                onFilterTable(data, true);
              }}
              fieldPrefix='FILTER'
              fields={[
                ...filterFields,
                {
                  colSize:
                    'col-span-2 xl:col-start-11 sm:col-start-10 sm:col-end-11 flex justify-end',
                  controlType: (
                    <Tooltip title='Reset'>
                      <MainButton
                        type='reset'
                        className='mt-7 ml-auto mr-0 mx-auto w-full'
                        icon={ICONS.reset}
                        onClick={() => {
                          let data = form.getFieldsValue();
                          let setAll = (obj, val) =>
                            Object.keys(obj).forEach((k) => (obj[k] = val));
                          setAll(data, null);
                          onFilterTable(data);
                          setQuery({ page: 1 });
                          form.setFieldsValue(data);
                        }}
                      />
                    </Tooltip>
                  ),
                },
                {
                  colSize:
                    'col-span-10 xl:col-start-12 sm:col-start-11 sm:col-end-13',
                  controlType: (
                    <MainButton
                      label='Search'
                      type='submit'
                      className='mt-7 ml-auto mr-0 mx-auto w-full'
                      icon={ICONS.search}
                    />
                  ),
                },
              ]}
            />
          );
        }}
        footer={() => {
          if (data) {
            return (
              <div className='flex justify-end'>
                <Pagination
                  className='mt-3'
                  defaultCurrent={1}
                  total={data?.pagination?.total}
                  responsive={true}
                  onChange={(e) => {
                    setQuery({ ...query, page: e });
                  }}
                  showQuickJumper={true}
                  showSizeChanger={false}
                  defaultPageSize={data?.pagination?.per_page}
                  current={data?.pagination?.current_page ?? 1}
                />
              </div>
            );
          }
        }}
      />
    </div>
  );
};

export default Table;
