import { store } from 'src';
import { axios } from 'src/config/axios';
import config from 'src/config/config';
import { startLoader, stopLoader } from '../actions/loader';
import ApiFactory from './ApiFactory';

const endpoint = 'timer';

const TimeTrackerService = {
  ...ApiFactory({ endpoint }),
  async start(data) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.post(`/${endpoint}/start`, data);
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
  async stop(data) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.patch(`/${endpoint}/stop`, data);
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
  async updateRunningTimer(data) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.patch(`/${endpoint}/running`, data);
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
  async cancel() {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.delete(`/${endpoint}/discard`, {});
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
  async current() {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.get(`/${endpoint}/running`);
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
};

export default TimeTrackerService;
