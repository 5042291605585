import { axios } from 'src/config/axios';
import config from 'src/config/config';
import ApiFactory from './ApiFactory';

const endpoint = 'time-records';

const TimeRecordsService = {
  ...ApiFactory({ endpoint }),
  async all({ page, query }) {
    let response;
    try {
      response = await axios.get(
        `/${endpoint}?per_page=50&page=${page}${query || ''}`
      );
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
    }
    return response;
  },
  async togglePaidStatus(id) {
    let response;
    try {
      response = await axios.patch(`/${endpoint}/${id}/toggle-paid`, {});
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
    }
    return response;
  },
  async export({ query }) {
    let response;
    // store.dispatch(startLoader());
    try {
      response = await axios.get(
        `/${endpoint}/export${query ? '?' + query : ''}`
      );
      // store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      // store.dispatch(stopLoader());
    }
    return response;
  },
};

export default TimeRecordsService;
