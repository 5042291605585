import { Form } from 'antd';
import React from 'react';
import KpiTemplatesService from 'src/common/api/KpiTemplatesService';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './kpi-templates-fields';
import { useNavigate } from 'react-router';

const KpiTemplatesAdd = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    KpiTemplatesService.create(data).then((res) => {
      if (res) {
        form.resetFields();
        navigate('/kpi/templates');
      }
    });
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={mainFields('KPI Template - Create')}
      />
    </div>
  );
};

export default KpiTemplatesAdd;
