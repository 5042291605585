import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setProjects } from 'src/common/actions/projects';
import { setTags } from 'src/common/actions/tags';
import { setTrackedTimes } from 'src/common/actions/trackedTimes';
import ProjectsService from 'src/common/api/ProjectsService';
import TagsService from 'src/common/api/TagsService';
import TimeRecordsService from 'src/common/api/TimeRecordsService';
import Utils from 'src/common/Utils';
import TimesList from './times-list/TimesList';

const TimeTracker = () => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllTrackedTimes();
    getProjects();
    getTags();

    const unsubscribe = Utils.observeStore(({ timer }) => {
      // console.log('TIME TRACKER', timer.stopped);
      // if (timer.stopped) {
      //   console.log('FETCHUJ NOVE PODATKE');
      // }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const getAllTrackedTimes = async (page = 1) => {
    let response = await TimeRecordsService.all({ page });
    setData(response);
    dispatch(setTrackedTimes(response));
  };

  const getTags = async () => {
    let { data } = await TagsService.allWithoutPagination();
    dispatch(setTags(data));
  };

  const getProjects = async () => {
    let { data } = await ProjectsService.allWithoutPagination();
    dispatch(setProjects(data));
  };

  return (
    <div>
      <TimesList getAllTrackedTimes={getAllTrackedTimes} />
      <Pagination
        defaultCurrent={1}
        responsive={true}
        total={data?.pagination?.total}
        defaultPageSize={50}
        onChange={(e) => getAllTrackedTimes(e)}
      />
    </div>
  );
};

export default TimeTracker;
