import { Form } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import MaintenancesService from 'src/common/api/MaintenancesService';
import FormFactory from 'src/components/FormFactory';
import { editFields } from './maintenances-fields';
import MaintenancesTimes from './MaintenancesTimes';

const MaintenancesEditPostpaid = () => {
  let { maintenanceId } = useParams();
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState([]);

  useEffect(() => {
    getData(maintenanceId);
  }, [maintenanceId]);

  const getData = async (id) => {
    let { data } = await MaintenancesService.one(id);
    form.setFieldsValue({
      ...data,
      lease_start: moment(data.lease_start),
      lease_end: moment(data.lease_end),
      installments: mapInstallments(data.installments),
    });
    setFormFields(editFields({ installmentsFields: data.installments }));
  };

  const onSubmit = (data) => {
    MaintenancesService.update(maintenanceId, data);
  };

  const mapInstallments = (installments) => {
    let installmentsMapped = {};
    installments.forEach((item) => {
      installmentsMapped[item.label] = item.value;
    });
    return installmentsMapped;
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={formFields}
      />

      <MaintenancesTimes maintenanceId={maintenanceId} />
    </div>
  );
};

export default MaintenancesEditPostpaid;
