import config from 'src/config/config';
import { axios } from '../../config/axios';

const endpoint = 'reports';

const ReportsService = {
  async statistics({ query }) {
    let response;
    // store.dispatch(startLoader());
    try {
      response = await axios.get(
        `/${endpoint}/statistics${query ? '?' + query : ''}`
      );
      // store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      // store.dispatch(stopLoader());
    }
    return response;
  },
};

export default ReportsService;
