import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from 'src/common/actions/modal';
import ClientsService from 'src/common/api/ClientsService';
import { ICONS } from 'src/common/Icons';
import MainButton from 'src/components/buttons/MainButton';
import Table from 'src/components/Table';
import { filterFields, tableColumns } from './clients-fields';
import ClientsAdd from './ClientsAdd';

const ClientsList = () => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async (query = '') => {
    let response = await ClientsService.all({ query });
    setData(response);
  };

  const onCreate = () => {
    dispatch(
      openModal({
        title: 'Create client',
        component: <ClientsAdd onSuccess={getData} />,
      })
    );
  };

  const onDelete = ({ id }) => {
    ClientsService.delete(id).then(() => {
      getData();
    });
  };

  return (
    <div>
      <div className='flex justify-between items-center flex-wrap mb-2'>
        <h2 className='font-normal'>Clients</h2>
        <MainButton
          label='Create'
          onClick={onCreate}
          icon={ICONS.add}
        />
      </div>

      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields}
        onFilter={(query) => {
          getData(query);
        }}
        onDelete={(data) => onDelete(data)}
      />
    </div>
  );
};

export default ClientsList;
