import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import ClientsService from 'src/common/api/ClientsService';
import ProjectsService from 'src/common/api/ProjectsService';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './project-fields';
import { store } from 'src';
import ROLES from 'src/common/enums/roles';
import { closeModal } from 'src/common/actions/modal';
import { useDispatch } from 'react-redux';

const ProjectsAdd = ({ onSuccess }) => {
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const user = store.getState().auth?.user?.data;
  const isAdmin = [ROLES.Superadmin, ROLES.Admin].includes(user?.role_id);
  const dispatch = useDispatch();

  useEffect(() => {
    getClients();
  }, []);

  const onSubmit = (data) => {
    ProjectsService.create(data).then((res) => {
      if (!res.status) {
        onSuccess();
        form.resetFields();
        dispatch(closeModal());
      }
    });
  };

  const getClients = async () => {
    let response = await ClientsService.allWithoutPagination();
    setFormFields(
      mainFields({
        clientsDropdown: response.data,
        isAdmin,
      })
    );
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={formFields}
      />
    </div>
  );
};

export default ProjectsAdd;
