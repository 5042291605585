import { Input } from 'antd';
import { ICONS } from 'src/common/Icons';
import MainButton from 'src/components/buttons/MainButton';

export const tableColumns = [
  {
    title: 'Task name',
    dataIndex: 'name',
    key: 'name',
  },
];

export const mainFields = (projectId) => {
  return [
    {
      controlType: <Input />,
      colSize: 'col-span-12 ',
      options: {
        label: 'Name',
        name: 'name',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input client name!',
          },
        ],
      },
    },
    {
      controlType: <Input />,
      options: {
        name: 'project_id',
        value: +projectId,
        hidden: true,
      },
    },
    {
      colSize: 'col-span-3 ',
      controlType: (
        <MainButton
          label='Submit'
          type='submit'
          className='mt-2 mx-auto w-full'
          icon={ICONS.save}
        />
      ),
    },
  ];
};
