import { LOADER_START, LOADER_STOP } from 'src/common/actions/loader';
import LOADER from 'src/common/enums/loader';

export default function loader(
  state = {
    loading: false,
    completed: LOADER.Unactive,
  },
  action
) {
  switch (action.type) {
    case LOADER_START:
      return Object.assign({}, state, {
        loading: true,
        completed: LOADER.Unactive,
      });
    case LOADER_STOP:
      return Object.assign({}, state, {
        loading: false,
        completed: action.completed,
      });
    default:
      return state;
  }
}
