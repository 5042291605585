import { Form } from 'antd';
import React, { useEffect } from 'react';
import MaintenancesService from 'src/common/api/MaintenancesService';
import FormFactory from 'src/components/FormFactory';
import { closeModal } from 'src/common/actions/modal';
import { useDispatch } from 'react-redux';

const MaintenancesAdd = ({ onSuccess, clientId, formFields, postpaid }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.resetFields();
  }, [formFields]);

  const onSubmit = (data) => {
    let prePaidData = { ...data, lease_start: null, lease_end: null };
    MaintenancesService.create(postpaid ? data : prePaidData, clientId).then(
      (res) => {
        if (!res.status) {
          onSuccess();
          dispatch(closeModal());
          form.resetFields();
        }
      }
    );
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={formFields}
      />
    </div>
  );
};

export default MaintenancesAdd;
