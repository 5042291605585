import React from "react";
import { Form } from "antd";
import ErrorBoundary from "./ErrorBoundary";
import Toast from "src/common/Toast";
import Utils from "src/common/Utils";
import { v4 as uuid } from "uuid";

/**
 * Form component that generates a form based on the sepcified fields configuration
 *
 * @component
 * @param {object} props - Component props
 * @param {object[]} props.fields - Array of field configurations for the form
 * @param {function} props.onFinish - Function to handle form submission when it succeeds.
 * @param {object} props.form - Antd Form component
 * @param {string} [props.fieldPrefix = ""] - An optional prefix to be added to form field names.
 * @returns {JSX.Element} The generated form component.
 */


const FormFactory = ({ fields, onFinish, form, fieldPrefix = "" }) => {
  /**
   * Handles form submission failure.
   *
   * @param {object} errorInfo - Information about the form submission error.
   */
  const onFinishFailed = (errorInfo) => {
    Toast.error("Please check all form fields again.");
  };

  /**
   * Sets the field name by splitting it if it contains a comma or returning it as is.
   *
   * @param {string} fieldName - The field name to be processed.
   * @returns {string|string[]} The processed field name(s).
   */

  const setFieldName = (fieldName) => {
    if (fieldName?.includes(",")) {
      return fieldName.split(",");
    } else {
      return fieldName;
    }
  };

  return (
    <ErrorBoundary>
      <Form
        form={form}
        initialValues={Utils.generateFormInitialValues(
          Utils.addPrefixToFormFields(
            fields,
            `${fieldPrefix !== "" ? fieldPrefix + "_" : ""}`
          )
        )}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="grid grid-cols-12 gap-4">
          {Utils.addPrefixToFormFields(fields, fieldPrefix).map(
            (field, index) => {
              if (field?.group) {
                return (
                  <div className={field.colSize} key={index}>
                    {field?.group.map((subfield, indexGroup) => {
                      return (
                        <Form.Item
                          {...subfield?.options}
                          key={subfield?.options?.name || indexGroup}
                        >
                          {subfield?.controlType}
                        </Form.Item>
                      );
                    })}
                  </div>
                );
              } else {
                return (
                  <Form.Item
                    {...field?.options}
                    key={field?.options?.name || uuid()}
                    {...(field?.options?.name
                      ? { name: setFieldName(field?.options?.name) }
                      : {})}
                    className={field?.colSize + " !mb-0"}
                  >
                    {field.controlType}
                  </Form.Item>
                );
              }
            }
          )}
        </div>
      </Form>
    </ErrorBoundary>
  );
};

export default FormFactory;
