import { Form } from 'antd';
import React, { useEffect } from 'react';
import ProfileService from 'src/common/api/ProfileService';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './profile-fields';

const ProfileEdit = () => {
  const [form] = Form.useForm();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let { data } = await ProfileService.getInfo();
    form.setFieldsValue(data);
  };

  const onSubmit = (data) => {
    ProfileService.update(data).then(() => {});
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={mainFields({})}
      />
    </div>
  );
};

export default ProfileEdit;
