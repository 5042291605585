import { axios } from 'src/config/axios';
import ApiFactory from './ApiFactory';

const KpiService = ApiFactory({ endpoint: 'kpis' });

export const toggleKpiCompletion = async (id) => {
  try {
    await axios.patch(`/kpis/${id}/toggle`);
  } catch (err) {
    throw err;
  }
};

export default KpiService;
