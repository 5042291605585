import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { openModal } from 'src/common/actions/modal';
import MaintenancesService from 'src/common/api/MaintenancesService';
import { ICONS } from 'src/common/Icons';
import MainButton from 'src/components/buttons/MainButton';
import Table from 'src/components/Table';
import {
  addFieldsPostpaid,
  addFieldsPrepaid,
  tableColumnsPostpaid,
  tableColumnsPrepaid,
} from './maintenances-fields';
import MaintenancesAdd from './MaintenancesAdd';
import ProjectsService from 'src/common/api/ProjectsService';

const MaintenancesList = ({ clientId }) => {
  const [prepaidData, setPrepaidData] = useState(null);
  const [postpaidData, setPostpaidData] = useState(null);
  const [projects, setProjects] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getPrepaidData();
    getPostpaidData();
    getProjects();
  }, []);

  const getPostpaidData = async (page = 1) => {
    let response = await MaintenancesService.all({ page, clientId });
    setPostpaidData(null);
    setPostpaidData(response);
  };

  const getProjects = async () => {
    let response = await ProjectsService.allWithoutPagination();
    setProjects(response?.data);
  };

  const getPrepaidData = async (page = 1) => {
    let response = await MaintenancesService.all({
      page,
      clientId,
      is_postpaid: 1,
    });

    setPrepaidData(null);
    setPrepaidData(response);
  };

  const onCreate = ({ formFields, postpaid }) => {
    dispatch(
      openModal({
        title: 'Create maintenance',
        component: (
          <MaintenancesAdd
            formFields={formFields}
            onSuccess={postpaid ? getPostpaidData : getPrepaidData}
            clientId={clientId}
            postpaid={postpaid}
          />
        ),
      })
    );
  };

  const onDeletePostpaid = ({ id }) => {
    MaintenancesService.delete(id).then(() => {
      getPostpaidData();
    });
  };

  const onDeletePrepaid = ({ id }) => {
    MaintenancesService.delete(id).then(() => {
      getPrepaidData();
    });
  };

  return (
    <div className='mt-5'>
      <div className='flex justify-between items-center flex-wrap mb-2'>
        <h2 className='font-normal'>Maintenances</h2>
      </div>

      <div className='flex flex-wrap justify-between items-center mb-3'>
        <h3 className='font-normal m-0'>Prepaid</h3>
        <MainButton
          label='Create'
          onClick={() =>
            onCreate({
              formFields: addFieldsPostpaid({ projectsDropdown: projects }),
              postpaid: true,
            })
          }
          icon={ICONS.add}
        />
      </div>
      {postpaidData && (
        <Table
          data={postpaidData}
          columns={tableColumnsPostpaid}
          onFilter={(page) => getPostpaidData(page)}
          onDelete={(data) => onDeletePostpaid(data)}
          onEdit={(data) => navigate(`postpaid/${data.id}`)}
        />
      )}

      <div className='flex flex-wrap justify-between items-center mb-3'>
        <h3 className='font-normal m-0 mb-2'>Postpaid</h3>
        <MainButton
          label='Create'
          onClick={() =>
            onCreate({
              formFields: addFieldsPrepaid({ projectsDropdown: projects }),
            })
          }
          icon={ICONS.add}
        />
      </div>
      {prepaidData && (
        <Table
          data={prepaidData}
          columns={tableColumnsPrepaid}
          onFilter={(page) => getPrepaidData(page)}
          onDelete={(data) => onDeletePrepaid(data)}
          onEdit={(data) => navigate(`prepaid/${data.id}`)}
        />
      )}
    </div>
  );
};

export default MaintenancesList;
