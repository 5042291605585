import { Form } from 'antd';
import React, { useEffect } from 'react';
import UsersService from 'src/common/api/UsersService';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './users-fields';

const UsersEdit = ({ id, onSuccess }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    getData(id);
  }, [id]);

  const getData = async (id) => {
    let { data } = await UsersService.one(id);
    form.setFieldsValue(data);
  };

  const onSubmit = (data) => {
    UsersService.update(id, data).then(() => {
      onSuccess();
    });
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={mainFields({})}
      />
    </div>
  );
};

export default UsersEdit;
