import { Form, Select } from "antd";
import React, { useEffect } from "react";
const { Option } = Select;

const ProjectsDropdown = ({ getTasks, projects, onSave }) => {
  useEffect(() => {}, [projects]);

  return (
    <Form.Item name="project_id" className="w-[130px] mb-0 ">
      <Select
        onSelect={(selected) => {
          getTasks(selected);
          if (onSave) onSave();
        }}
        dropdownMatchSelectWidth={false}
        placeholder="Project"
        optionFilterProp="children"
        showSearch={true}
      >
        {projects &&
          projects?.map((option) => {
            return (
              <Option key={option.id} value={option.id}>
                {option.title}
              </Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};

export default ProjectsDropdown;
