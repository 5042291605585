import { SET_PROJECTS } from '../actions/projects';

export default function projects(
  state = {
    projects: null,
  },
  action
) {
  switch (action.type) {
    case SET_PROJECTS:
      return Object.assign({}, state, {
        projects: action.projects,
      });
    default:
      return state;
  }
}
