import { RESUME_TIMER } from '../actions/resumeTimer';

export default function resumeTimer(
  state = {
    data: null,
  },
  action
) {
  switch (action.type) {
    case RESUME_TIMER:
      return Object.assign({}, state, {
        data: action.data,
      });
    default:
      return state;
  }
}
