import { Form } from "antd";
import React, { useEffect, useState } from "react";
import FormFactory from "src/components/FormFactory";
import { mainFields } from "./licenses-fields";
import LicensesService from "src/common/api/LicensesService";
import ClientsService from "src/common/api/ClientsService";
import ProjectsService from "src/common/api/ProjectsService";
import { closeModal } from "src/common/actions/modal";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

const LicensesEdit = ({ id, onSuccess }) => {
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getData(id);
  }, [id]);

  const getData = async (id) => {
    let { data } = await LicensesService.one(id);

    form.setFieldsValue({
      ...data,
      client_id: data.client.id,
      project_id: data.project.id,
      lease_start: dayjs(data.lease_start),
      lease_end: dayjs(data.lease_end),
      notify_emails: data.notify_emails?.join(","),
    });
    getClients();
  };

  const onSubmit = (data) => {
    LicensesService.update(id, data).then(() => {
      onSuccess();
      dispatch(closeModal());
    });
  };

  const getClients = async () => {
    let response = await ClientsService.allWithoutPagination();
    getProjects(response.data);
  };

  const getProjects = async (clients) => {
    let response = await ProjectsService.allWithoutPagination();
    setFormFields(
      mainFields({
        projectsDropdown: response.data,
        clientsDropdown: clients,
        headerTitle: "Licenses - Edit",
      })
    );
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit({
            ...data,
            notify_emails: data.notify_emails?.split(","),
          });
        }}
        fields={formFields}
      />
    </div>
  );
};

export default LicensesEdit;
