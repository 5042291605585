import { useDispatch } from 'react-redux';
import { loadUserFromLocalStorage } from 'src/common/actions/user';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Router from 'src/components/Router';
import Utils from 'src/common/Utils';
import { message } from 'antd';
import LOADER from 'src/common/enums/loader';

function App() {
  const dispatch = useDispatch();
  dispatch(loadUserFromLocalStorage());
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';
  const loaderMessages = {
    [LOADER.Success]: 'Action successfuly completed!',
    [LOADER.Failed]: 'Action failed!',
  };

  useEffect(() => {
    let timeout;

    const startLoader = () => {
      messageApi.open({
        key,
        type: 'loading',
        content: 'Action in progress..',
        duration: 0,
      });
    };

    const unstable_stopLoader = (completed) => {
      if (completed === LOADER.Unactive) {
        messageApi?.destroy();
        return;
      } else {
        messageApi.open({
          key,
          type: completed === LOADER.Success ? 'success' : 'error',
          content: loaderMessages[completed],
          duration: 2,
        });
        timeout = setTimeout(() => {
          messageApi?.destroy();
        }, 2000);
      }
    };

    const stopLoader = (completed) => {
      messageApi?.destroy();
    };

    const unsubscribe = Utils.observeStore(({ loader }) => {
      // Utils.logStore();
      if (loader.loading) startLoader();
      if (!loader.loading) stopLoader(loader.completed);
    });

    return () => {
      unsubscribe();
      clearTimeout(timeout);
    };
  });

  return (
    <>
      {contextHolder}
      <Router />
      <ToastContainer
        containerId='my-toaster'
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        theme='colored'
      />
    </>
  );
}

export default App;
