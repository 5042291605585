import { Form } from "antd";
import React, { useEffect, useState } from "react";
import ClientsService from "src/common/api/ClientsService";
import FormFactory from "src/components/FormFactory";
import { mainFields } from "./licenses-fields";
import LicensesService from "src/common/api/LicensesService";
import ProjectsService from "src/common/api/ProjectsService";
import { closeModal } from "src/common/actions/modal";
import { useDispatch } from "react-redux";

const LicensesAdd = ({ onSuccess }) => {
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getClients();
  }, []);

  const onSubmit = (data) => {
    LicensesService.create(data).then((res) => {
      if (!res.status) {
        onSuccess();
        form.resetFields();
        dispatch(closeModal());
      }
    });
  };

  const getClients = async () => {
    let response = await ClientsService.allWithoutPagination();
    getProjects(response.data);
  };

  const getProjects = async (clients) => {
    let response = await ProjectsService.allWithoutPagination();
    console.log(response);
    setFormFields(
      mainFields({
        projectsDropdown: response.data,
        clientsDropdown: clients,
        headerTitle: "Licenses - Edit",
      })
    );
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit({
            ...data,
            notify_emails: data.notify_emails?.split(","),
          });
        }}
        fields={formFields}
      />
    </div>
  );
};

export default LicensesAdd;
