import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from 'src/common/actions/modal';
import { setTags } from 'src/common/actions/tags';
import TagsService from 'src/common/api/TagsService';
import { ICONS } from 'src/common/Icons';
import MainButton from 'src/components/buttons/MainButton';
import Table from 'src/components/Table';
import { tableColumns } from './tags-fields';
import TagsAdd from './TagsAdd';
import TagsEdit from './TagsEdit';

const TagsList = () => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async (query = '') => {
    let response = await TagsService.all({ query });
    setData(response);
    getTags();
  };

  const getTags = async () => {
    let { data } = await TagsService.allWithoutPagination();
    dispatch(setTags(data));
  };

  const onCreate = () => {
    dispatch(
      openModal({
        title: 'Create tag',
        component: <TagsAdd onSuccess={getData} />,
      })
    );
  };

  const openEditModal = ({ id }) => {
    dispatch(
      openModal({
        title: 'Edit tag',
        component: (
          <TagsEdit
            id={id}
            onSuccess={getData}
          />
        ),
      })
    );
  };

  const onDelete = ({ id }) => {
    TagsService.delete(id).then(() => {
      getData();
    });
  };

  return (
    <div>
      <div className='flex justify-between items-center flex-wrap mb-2'>
        <h2 className='font-normal'>Tags</h2>
        <MainButton
          label='Create'
          onClick={onCreate}
          icon={ICONS.add}
        />
      </div>

      <Table
        data={data}
        columns={tableColumns}
        onFilter={(query) => getData(query)}
        onEdit={(data) => openEditModal(data)}
        onDelete={(data) => onDelete(data)}
      />
    </div>
  );
};

export default TagsList;
