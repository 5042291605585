import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from 'src/common/actions/modal';
import UsersService from 'src/common/api/UsersService';
import { ICONS } from 'src/common/Icons';
import MainButton from 'src/components/buttons/MainButton';
import Table from 'src/components/Table';
import { filterFields, tableColumns } from './users-fields';
import UsersAdd from './UsersAdd';
import UsersEdit from './UsersEdit';
import ROLES from 'src/common/enums/roles';
import { store } from 'src';

const UsersList = () => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const user = store.getState().auth?.user?.data;
  const isAdmin = [ROLES.Superadmin, ROLES.Admin].includes(user?.role_id);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (query = '') => {
    let response = await UsersService.all({ query });
    setData(response);
  };

  const onCreate = () => {
    dispatch(
      openModal({
        title: 'Create user',
        component: <UsersAdd onSuccess={getData} />,
      })
    );
  };

  const openEditModal = ({ id }) => {
    dispatch(
      openModal({
        title: 'Edit user',
        component: (
          <UsersEdit
            id={id}
            onSuccess={getData}
          />
        ),
      })
    );
  };

  const onDelete = ({ id }) => {
    UsersService.delete(id).then(() => {
      getData();
    });
  };

  return (
    <div>
      <div className='flex justify-between items-center flex-wrap mb-2'>
        <h2 className='font-normal '>Users</h2>
        <MainButton
          label='Create'
          onClick={onCreate}
          icon={ICONS.add}
          hide={!isAdmin}
        />
      </div>

      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields}
        onFilter={(query) => getData(query)}
        onEdit={(data) => openEditModal(data)}
        onDelete={(data) => onDelete(data)}
        hideActions={!isAdmin}
      />
    </div>
  );
};

export default UsersList;
