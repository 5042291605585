import { Input, Select } from 'antd';
import { colorOptions } from 'src/common/Static';
import Utils from 'src/common/Utils';
const { Option } = Select;

export const tableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'id',
  },
  {
    title: 'Color',
    key: 'color',
    dataIndex: 'color',
    width: '9rem',
    render: (_, data) => (
      <div
        style={{ background: data.color }}
        className='w-4 h-4 rounded-full'
      />
    ),
  },
];

export const mainFields = [
  ...Utils.createHeader({}),
  {
    controlType: <Input />,
    colSize: 'col-span-7 sm:col-span-8',
    options: {
      label: 'Name',
      name: 'name',
      value: null,
      rules: [
        {
          required: true,
          message: 'Please input tag name!',
        },
      ],
    },
  },
  {
    controlType: (
      <Select>
        {colorOptions.map((option) => {
          return (
            <Option key={option.value} value={option.value}>
              <div
                style={{ background: option.value }}
                className='rounded-md px-2 h-2 mt-[10px] text-white'
              ></div>
            </Option>
          );
        })}
      </Select>
    ),
    colSize: 'col-span-5 sm:col-span-4',
    options: {
      label: 'Color',
      name: 'color',
      value: null,
      rules: [
        {
          required: true,
          message: 'Please input tag color!',
        },
      ],
    },
  },
];
