import { SET_TRACKED_TIMES } from '../actions/trackedTimes';

export default function trackedTimes(
  state = {
    times: null,
  },
  action
) {
  switch (action.type) {
    case SET_TRACKED_TIMES:
      return Object.assign({}, state, {
        times: action.times,
      });
    default:
      return state;
  }
}
