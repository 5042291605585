import { Form, Switch, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import MaintenancesService from 'src/common/api/MaintenancesService';
import Table from 'src/components/Table';
import { filterFields, tableColumnsTimes } from './maintenances-fields';
import FormFactory from 'src/components/FormFactory';
import MainButton from 'src/components/buttons/MainButton';
import { ICONS } from 'src/common/Icons';
import Utils from 'src/common/Utils';
import TagsService from 'src/common/api/TagsService';

const MaintenancesTimes = ({ maintenanceId, switchLabel = 'Is paid' }) => {
  const [unpaidData, setUnpaidData] = useState(null);
  const [paidData, setPaidData] = useState(null);
  const [searchForm] = Form.useForm();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchFormFields, setSearchFormFields] = useState([]);

  const getTags = async () => {
    let response = await TagsService.allWithoutPagination();
    setSearchFormFields(
      filterFields({
        tagsDropdown: response.data,
      })
    );
  };

  useEffect(() => {
    getTags();
  }, [maintenanceId]);

  useEffect(() => {
    getUnpaidData();
    getPaidData();
  }, [maintenanceId, searchQuery]);

  const getUnpaidData = async (page = 1) => {
    let response = await MaintenancesService.allTimeRecords({
      page,
      maintenanceId,
      query: searchQuery,
    });
    setUnpaidData(response);
  };

  const getPaidData = async (page = 1) => {
    let response = await MaintenancesService.allTimeRecords({
      page,
      maintenanceId,
      isPaid: true,
      query: searchQuery,
    });
    setPaidData(response);
  };

  return (
    <div className='mt-5'>
      <h2 className='font-normal'>Maintenances</h2>
      {Utils.JSON(searchFormFields)}
      <FormFactory
        form={searchForm}
        onFinish={(data) => {
          setSearchQuery(Utils.createQueryString(data));
        }}
        fields={[
          ...searchFormFields,
          {
            colSize:
              'col-span-2 xl:col-start-11 sm:col-start-10 sm:col-end-11 flex justify-end',
            controlType: (
              <Tooltip title='Reset'>
                <MainButton
                  type='reset'
                  className='mt-7 ml-auto mr-0 mx-auto w-full'
                  icon={ICONS.reset}
                  onClick={() => {
                    setSearchQuery('');
                  }}
                />
              </Tooltip>
            ),
          },
          {
            colSize:
              'col-span-10 xl:col-start-12 sm:col-start-11 sm:col-end-13',
            controlType: (
              <MainButton
                label='Search'
                type='submit'
                className='mt-7 ml-auto mr-0 mx-auto w-full'
                icon={ICONS.search}
              />
            ),
          },
        ]}
      />

      <h3 className='font-normal mb-2'>Unpaid</h3>
      {unpaidData && (
        <Table
          data={{
            data: unpaidData?.data?.time_records,
            pagination: unpaidData?.pagination,
          }}
          columns={[
            ...tableColumnsTimes,
            {
              title: switchLabel,
              dataIndex: 'is_paid',
              key: 'is_paid',
              render: (_, data) => {
                return (
                  <Switch
                    {...(data.is_paid ? { defaultChecked: true } : {})}
                    onChange={() => {
                      MaintenancesService.togglePaidStatus(
                        maintenanceId,
                        data.id
                      ).then((res) => {
                        if (!res.status) {
                          getUnpaidData();
                          getPaidData();
                        }
                      });
                    }}
                  />
                );
              },
            },
          ]}
          onFilter={(page) => getUnpaidData(page)}
          hideActions={true}
        />
      )}
      <h3 className='font-normal mb-2'>Paid</h3>
      {paidData && (
        <Table
          data={{ data: paidData?.data?.time_records }}
          columns={[
            ...tableColumnsTimes,
            {
              title: switchLabel,
              dataIndex: 'is_paid',
              key: 'is_paid',
              render: (_, data) => {
                return (
                  <Switch
                    {...(data.is_paid ? { defaultChecked: true } : {})}
                    onChange={() => {
                      MaintenancesService.togglePaidStatus(
                        maintenanceId,
                        data.id
                      ).then((res) => {
                        if (!res.status) {
                          getUnpaidData();
                          getPaidData();
                        }
                      });
                    }}
                  />
                );
              },
            },
          ]}
          onFilter={(page) => getPaidData(page)}
          hideActions={true}
        />
      )}
    </div>
  );
};

export default MaintenancesTimes;
