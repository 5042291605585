import { useEffect, useState } from 'react';
import { filterFields, tableColumns } from './kpi-user-fields';
import Table from 'src/components/Table';
import KpiUserService from 'src/common/api/KpiUserService';
import { useNavigate } from 'react-router';
import Utils from 'src/common/Utils';
import ROLES from 'src/common/enums/roles';
import { store } from 'src';

const KpiUserList = () => {
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const user = store.getState().auth?.user?.data;
  const isAdmin = [ROLES.Superadmin, ROLES.Admin].includes(user?.role_id);

  useEffect(() => {
    if (isAdmin) {
      const currentYear = new Date().getFullYear();
      const currentQuarter = Utils.getCurrentQuarter();

      getData(`&quarter=${currentQuarter}&year=${currentYear}`);
    } else {
      navigate(`/kpi/users/${user?.id}`);
    }
  }, []);

  const getData = async (query = '') => {
    if (isAdmin) {
      const response = await KpiUserService.getAll(query);
      setData(response);
    }
  };

  return (
    <div>
      <div className='flex items-center flex-wrap mb-2'>
        <h2 className='font-normal'>KPI - Users</h2>
      </div>
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields()}
        onFilter={(query) => {
          getData(query);
        }}
        onRowClick={(data) => {
          if (isAdmin || user?.role_id === data?.id) {
            navigate(`/kpi/users/${data?.id}`);
          }
        }}
        hideActions={true}
      />
    </div>
  );
};

export default KpiUserList;
