import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from 'src/common/actions/modal';
import ProjectsService from 'src/common/api/ProjectsService';
import { ICONS } from 'src/common/Icons';
import MainButton from 'src/components/buttons/MainButton';
import Table from 'src/components/Table';
import { filterFields, tableColumns } from './project-fields';
import ProjectsAdd from './ProjectsAdd';
import { setProjects } from 'src/common/actions/projects';
import { store } from 'src';
import ROLES from 'src/common/enums/roles';

const ProjectsList = () => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const user = store.getState().auth?.user?.data;
  const isAdmin = [ROLES.Superadmin, ROLES.Admin].includes(user?.role_id);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (query = '') => {
    let response = await ProjectsService.all({ query });
    setData(response);
    getProjects();
  };

  const getProjects = async () => {
    let { data } = await ProjectsService.allWithoutPagination();
    dispatch(setProjects(data));
  };

  const onCreate = () => {
    dispatch(
      openModal({
        title: 'Create project',
        component: <ProjectsAdd onSuccess={getData} />,
      })
    );
  };

  const onDelete = ({ id }) => {
    ProjectsService.delete(id).then(() => {
      getData();
    });
  };

  return (
    <div>
      <div className='flex justify-between items-center flex-wrap mb-2'>
        <h2 className='font-normal'>Projects</h2>
        <MainButton
          label='Create'
          onClick={onCreate}
          icon={ICONS.add}
          hide={!isAdmin}
        />
      </div>
      <Table
        data={data}
        columns={tableColumns(isAdmin)}
        filterFields={filterFields}
        onFilter={(query) => getData(query)}
        onDelete={(data) => onDelete(data)}
        hideDelete={!isAdmin}
      />
    </div>
  );
};

export default ProjectsList;
