/**
 * A utility object for interacting with the browser's local storage.
 * Provides methods to get, set, and remove data from local storage.
 *
 * @namespace Storage
 */

const storage = {
  /**
   * Retrieves a value from local storage by the specified key.
   *
   * @param {string} key - The key used to retrieve the value.
   * @param {*} [defaultVal = undefined] - The default value to return if the key is not found.
   * @returns {*} The value associated with the key, or the default value if not found.
   */
  get(key, defaultVal = undefined) {
    return JSON.parse(localStorage.getItem(key)) ?? defaultVal;
  },

  /**
   * Sets a key-value pair in local storage.
   *
   * @param {string} key - The key to set.
   * @param {*} value - The value to associate with the key.
   */
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  /**
   * Removes a key-value pair from local storage by the specified key.
   * @param {string} key - The key to remove.
   */
  remove(key) {
    localStorage.removeItem(key);
  },
};

export default storage;
