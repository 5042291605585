import { Form } from 'antd';
import React from 'react';
import ClientsService from 'src/common/api/ClientsService';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './clients-fields';
import { useDispatch } from 'react-redux';
import { closeModal } from 'src/common/actions/modal';

const ClientsAdd = ({ onSuccess }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    ClientsService.create(data).then((res) => {
      if (!res.status) {
        onSuccess();
        form.resetFields();
        dispatch(closeModal());
      }
    });
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={mainFields({})}
      />
    </div>
  );
};

export default ClientsAdd;
