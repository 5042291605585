import { DatePicker, Input, Select, Switch, Tag } from 'antd';
import moment from 'moment';
import MaintenancesService from 'src/common/api/MaintenancesService';
import TimeRecordsService from 'src/common/api/TimeRecordsService';
import { ICONS } from 'src/common/Icons';
import Utils from 'src/common/Utils';
import MainButton from 'src/components/buttons/MainButton';
import config from 'src/config/config';
const { TextArea } = Input;
const { Option } = Select;

export const filterFields = ({ tagsDropdown }) => {
  return [
    {
      controlType: (
        <Select
          optionFilterProp='children'
          showSearch={true}
          placeholder='Select tag'
        >
          {tagsDropdown.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Tag',
        name: 'tag_id',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please choose tag!',
          },
        ],
      },
    },
  ];
};

export const tableColumnsPostpaid = [
  {
    title: 'Project',
    dateIndex: ['project', 'title'],
    key: ['project', 'title'],
    render: (_, data) => <div>{data?.project?.title}</div>,
  },
  {
    title: 'Lease start',
    dataIndex: 'lease_start',
    key: 'lease_start',
    render: (_, data) => (
      <div>{moment(data?.lease_start).format(config.dateFormat)}</div>
    ),
  },
  {
    title: 'Lease end',
    dataIndex: 'lease_end',
    key: 'lease_end',
    render: (_, data) => (
      <div>{moment(data?.lease_end).format(config.dateFormat)}</div>
    ),
  },
  {
    title: 'Hours',
    dataIndex: 'maintenance_hours',
    key: 'maintenance_hours',
  },
  {
    title: 'Hours left',
    dataIndex: 'maintenance_hours_left',
    key: 'maintenance_hours_left',
  },
  {
    title: 'Status',
    dataIndex: 'is_active',
    key: 'is_active',
    render: (_, data) =>
      data.is_active ? (
        <Tag color='green'>Active</Tag>
      ) : (
        <Tag color='cyan'>Inactive</Tag>
      ),
  },
];
export const tableColumnsPrepaid = [
  {
    title: 'Project',
    dateIndex: ['project', 'title'],
    key: ['project', 'title'],
    render: (_, data) => <div>{data?.project?.title}</div>,
  },
  {
    title: 'Maintenance hours paid',
    dataIndex: 'maintenance_hours_paid',
    key: 'maintenance_hours_paid',
  },
  {
    title: 'Hourly rate',
    dataIndex: 'package_price',
    key: 'package_price',
  },
];

export const tableColumnsTimes = [
  {
    title: 'Project',
    dataIndex: ['project', 'title'],
    key: ['project', 'title'],
  },
  {
    title: 'User',
    dataIndex: ['user', 'name'],
    key: ['user', 'name'],
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Start time',
    dataIndex: 'start_time',
    key: 'start_time',
    render: (_, data) => {
      return moment(data.start_time).format(config.dateTimeFormat);
    },
  },
  {
    title: 'Payment date',
    dataIndex: 'payment_date',
    key: 'payment_date',
    render: (_, data) => {
      return data?.payment_date
        ? moment(data.payment_date).format(config.dateFormat)
        : '-';
    },
  },
  {
    title: 'Total time',
    dataIndex: 'total_time',
    key: 'total_time',
    render: (_, data) => {
      return Utils.calculateTrackedTime(data.total_time);
    },
  },
];

export const addFieldsPostpaid = ({ projectsDropdown }) => {
  return [
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Lease start',
        name: 'lease_start',
        value: null,
      },
    },
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Lease end',
        name: 'lease_end',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Package price',
        name: 'package_price',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Regular price',
        name: 'regular_price',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Installments number',
        name: 'installments_number',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Maintenance hours',
        name: 'maintenance_hours',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Monitoring hours monthly',
        name: 'monitoring_hours_monthly',
        value: null,
      },
    },
    {
      controlType: (
        <Select
          optionFilterProp='children'
          showSearch={true}
        >
          {projectsDropdown.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.title}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Project',
        name: 'project_id',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please choose project!',
          },
        ],
      },
    },
    {
      controlType: <TextArea />,
      colSize: 'col-span-12',
      options: {
        label: 'Note',
        name: 'note',
        value: null,
      },
    },
    {
      colSize: 'col-span-6 ',
      controlType: (
        <MainButton
          label='Submit'
          type='submit'
          className='mt-2 mx-auto w-fit'
          icon={ICONS.save}
        />
      ),
    },
  ];
};
export const addFieldsPrepaid = ({ projectsDropdown }) => [
  {
    controlType: <Input />,
    colSize: 'col-span-12',
    options: {
      label: 'Hourly rate',
      name: 'package_price',
      value: null,
    },
  },
  {
    controlType: <TextArea />,
    colSize: 'col-span-12',
    options: {
      label: 'Note',
      name: 'note',
      value: null,
    },
  },
  {
    controlType: (
      <Select
        optionFilterProp='children'
        showSearch={true}
      >
        {projectsDropdown.map((option) => {
          return (
            <Option
              key={option.id}
              value={option.id}
            >
              {option.title}
            </Option>
          );
        })}
      </Select>
    ),
    colSize: 'col-span-12',
    options: {
      label: 'Project',
      name: 'project_id',
      value: null,
      rules: [
        {
          required: true,
          message: 'Please choose project!',
        },
      ],
    },
  },
  {
    colSize: 'col-span-6 ',
    controlType: (
      <MainButton
        label='Submit'
        type='submit'
        className='mt-2 mx-auto w-fit'
        icon={ICONS.save}
      />
    ),
  },
];

export const editPrepaidFields = () => {
  return [
    {
      colSize: 'col-span-6 ',
      controlType: <h2 className='font-normal '>Maintenance - Edit</h2>,
    },
    {
      colSize: 'col-span-6 flex items-center justify-end',
      controlType: (
        <MainButton
          label='Submit'
          type='submit'
          className='mt-2 mx-auto w-fit'
          icon={ICONS.save}
        />
      ),
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-3',
      options: {
        label: 'Hourly rate',
        name: 'package_price',
        value: null,
      },
    },
    {
      controlType: <TextArea />,
      colSize: 'col-span-12',
      options: {
        label: 'Note',
        name: 'note',
        value: null,
      },
    },
  ];
};
export const editFields = ({ installmentsFields }) => {
  const fields = [];
  installmentsFields.forEach((installment) => {
    fields.push(
      createInstallmentFiled({
        label: installment.label,
        value: installment.value,
      })
    );
  });

  return [
    {
      colSize: 'col-span-6 ',
      controlType: <h2 className='font-normal '>Maintenance - Edit</h2>,
    },
    {
      colSize: 'col-span-6 flex items-center justify-end',
      controlType: (
        <MainButton
          label='Submit'
          type='submit'
          className='mt-2 mx-auto w-fit'
          icon={ICONS.save}
        />
      ),
    },
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          disabled={true}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-3',
      options: {
        label: 'Lease start',
        name: 'lease_start',
        value: null,
      },
    },
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          disabled={true}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-3',
      options: {
        label: 'Lease end',
        name: 'lease_end',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-3',
      options: {
        label: 'Package price',
        name: 'package_price',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-3',
      options: {
        label: 'Regular price',
        name: 'regular_price',
        value: null,
      },
    },
    {
      controlType: <Input disabled={true} />,
      colSize: 'col-span-12 sm:col-span-3',
      options: {
        label: 'Installments number',
        name: 'installments_number',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-3',
      options: {
        label: 'Maintenance hours',
        name: 'maintenance_hours',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-3',
      options: {
        label: 'Monitoring hours monthly',
        name: 'monitoring_hours_monthly',
        value: null,
      },
    },
    {
      controlType: <TextArea />,
      colSize: 'col-span-12',
      options: {
        label: 'Note',
        name: 'note',
        value: null,
      },
    },
    {
      colSize: 'col-span-12',
      controlType: <h2 className='font-normal mb-0'>Installments</h2>,
    },
    ...fields,
  ];
};

export const createInstallmentFiled = ({ label, value }) => {
  return {
    controlType: value ? <Switch defaultChecked={true} /> : <Switch />,
    colSize: 'col-span-6 sm:col-span-3',
    options: {
      label: label,
      name: ['installments', label],
      valuePropName: 'checked',
    },
  };
};
