import { Modal as AntModal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'src/common/actions/modal';
import Utils from 'src/common/Utils';

const Modal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const dispatch = useDispatch();

  const handleOk = () => {
    dispatch(closeModal());
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const unsubscribe = Utils.observeStore(({ modal }) => {
      setIsModalOpen(modal.opened);
      setModalData(modal);
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <AntModal
        title={modalData?.title}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        footer={false}
      >
        {modalData?.component}
      </AntModal>
    </>
  );
};

export default Modal;
