import { Input } from 'antd';
import { ICONS } from 'src/common/Icons';
import MainButton from 'src/components/buttons/MainButton';

export const loginFields = [
  {
    controlType: <Input />,
    colSize: 'col-span-12 ',
    options: {
      label: 'Email',
      name: 'email',
      rules: [
        {
          required: true,
          message: 'Please input your username!',
        },
      ],
    },
  },
  {
    controlType: <Input.Password />,
    colSize: 'col-span-12 ',
    options: {
      label: 'Password',
      name: 'password',
      rules: [
        {
          required: true,
          message: 'Please input your password!',
        },
      ],
    },
  },
  {
    colSize: 'col-span-12 ',
    controlType: (
      <MainButton
        label='Submit'
        type='submit'
        className='mt-4 mx-auto !w-full'
        icon={ICONS.login}
      />
    ),
  },
];

export const requestPasswordResetFields = [
  {
    controlType: <Input />,
    colSize: 'col-span-12 ',
    options: {
      label: 'Email',
      name: 'email',
      value: '',
      rules: [
        {
          required: true,
          message: 'Please input your email!',
        },
      ],
    },
  },
  {
    colSize: 'col-span-12 ',
    controlType: (
      <MainButton
        label='Request reset'
        type='submit'
        className='mt-4 mx-auto !w-full'
        icon={ICONS.login}
      />
    ),
  },
];

export const resetPasswordFields = [
  {
    controlType: <Input />,
    options: {
      name: 'token',
      hidden: true,
    },
  },
  {
    controlType: <Input />,
    colSize: 'col-span-12 ',
    options: {
      label: 'Email',
      name: 'email',
      value: '',
      rules: [
        {
          required: true,
          message: 'Please input your email!',
        },
      ],
    },
  },
  {
    controlType: <Input.Password />,
    colSize: 'col-span-12 ',
    options: {
      label: 'Password',
      name: 'password',
      value: '',
      rules: [
        {
          required: true,
          message: 'Please input your password!',
        },
      ],
    },
  },
  {
    controlType: <Input.Password />,
    colSize: 'col-span-12 ',
    options: {
      label: 'Confirm password',
      name: 'password_confirmation',
      value: '',
      rules: [
        {
          required: true,
          message: 'Please input password confirmation!',
        },
      ],
    },
  },
  {
    colSize: 'col-span-12 ',
    controlType: (
      <MainButton
        label='Reset password'
        type='submit'
        className='mt-4 mx-auto !w-full'
        icon={ICONS.login}
      />
    ),
  },
];
