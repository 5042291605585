import { Button, Divider, Form, Input, Select, Space } from 'antd';
import React, { useRef, useState } from 'react';
import TasksService from 'src/common/api/TasksService';
import { ICONS } from 'src/common/Icons';
import { v4 as uuid } from 'uuid';
const { Option } = Select;

const TasksDropdown = ({ form, getTasks, tasks, onSave }) => {
  const [taskName, setTaskName] = useState('');
  const taskNameInputRef = useRef(null);
  const search_id = uuid().slice(0, 8);
  const select_id = uuid().slice(0, 8);

  const onAddTask = () => {
    const { project_id } = form.getFieldValue();
    TasksService.create({ project_id, name: taskName }).then(() => {
      getTasks(project_id);
    });
  };

  const onTaskNameChange = (event) => {
    setTaskName(event.target.value);
  };

  return (
    <Form.Item
      name='task_id'
      className='w-[150px] mb-0'
    >
      <Select
        dropdownMatchSelectWidth={false}
        onSelect={() => {
          if (onSave) onSave();
        }}
        onClear={() => {
          form.setFieldValue('task_id', null);
          if (onSave) onSave();
        }}
        placeholder='Task'
        showSearch={true}
        allowClear={true}
        optionFilterProp='children'
        id={select_id}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: '8px 0',
              }}
            />
            <Space
              style={{
                padding: '0 8px 4px',
              }}
            >
              <Input
                id={search_id}
                placeholder='Please enter item'
                ref={taskNameInputRef}
                value={taskName}
                onChange={onTaskNameChange}
              />
              <Button
                type='text'
                icon={ICONS.add}
                onClick={onAddTask}
              >
                Add task
              </Button>
            </Space>
          </>
        )}
      >
        {tasks &&
          tasks?.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};

export default TasksDropdown;
