import React from 'react';
import FormFactory from 'src/components/FormFactory';
import logo from 'src/assets/main-logo-dark.svg';
import { requestPasswordResetFields } from './login-fields';
import { useNavigate } from 'react-router';
import axios from 'axios';
import config from 'src/config/config';
import Toast from 'src/common/Toast';

const RequestPasswordReset = () => {
  const navigate = useNavigate();

  const onSubmit = async (creds) => {
    await axios
      .post(`${config.baseUrl}/forgot-password`, creds)
      .then((response) => {
        Toast.success('A password reset request has been sent');
      })
      .catch((error) => {
        Toast.error(error?.response?.data?.message);
      });
  };

  return (
    <main className='flex h-screen w-screen justify-center items-center bg-[#F7F8FC]'>
      <section className='h-fit flex-col w-full sm:w-[390px] mx-auto '>
        <div className='flex items-center mb-6'>
          <img className='w-[200px] mx-auto' src={logo} alt='ITCentar' />
        </div>
        <div className='bg-white p-7 m-5 sm:m-0 rounded-lg '>
          <FormFactory
            onFinish={(credentials) => onSubmit(credentials)}
            fields={requestPasswordResetFields}
          />
          <p
            onClick={() => navigate('/login')}
            className='text-center text-sm hover:text-blue-400 cursor-pointer transition-all duration-300'
          >
            Back to login.
          </p>
        </div>
      </section>
    </main>
  );
};

export default RequestPasswordReset;
