import React, { useEffect } from 'react';
import { Alert, Button, Layout } from 'antd';
import { Dropdown, Space, Avatar } from 'antd';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'src/common/actions/user';
import { useNavigate } from 'react-router';
import { store } from 'src';
import { USERS_IDS } from 'src/common/enums/users';
import { ICONS } from 'src/common/Icons';
import { useState } from 'react';
import StatusSwitch from '../StatusSwitch';

const { Header } = Layout;

const AppHeader = () => {
  const [status, setStatus] = useState('office');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = store.getState().auth?.user;
  const [showHiddenMessage, setShowHiddenMessage] = useState(false);

  const items = [
    {
      label: (
        <div
          onClick={() => {
            navigate('/profile');
          }}
        >
          My profile
        </div>
      ),
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div
          onClick={() => {
            dispatch(logoutUser());
          }}
        >
          Sign out
        </div>
      ),
      key: '1',
      danger: true,
    },
  ];

  var stupidButton = {
    init: function () {
      this.attachEvents();
    },
    attachEvents: function () {
      const runaway = document.getElementById('runaway');
      runaway.addEventListener('mouseover', this.flyAway);
      runaway.addEventListener('click', this.showWin);
    },
    flyAway: (e) => {
      let mLeft = Math.abs(Math.random() - 0.2) * window.innerWidth;
      let mTop = Math.abs(Math.random() - 0.2) * window.innerHeight;

      const runaway = document.getElementById('runaway');
      runaway.style.position = 'fixed';
      runaway.style.top = `${mLeft}px`;
      runaway.style.left = `${mTop}px`;
    },
    showWin: function (e) {
      alert('Sram te bilo!');
    },
  };

  useEffect(() => {
    stupidButton.init();
  }, []);

  return (
    <>
      {user?.data?.id === USERS_IDS.ALEKSANDRA && (
        <Alert
          message='Aleksandra pročitaj knjigu!'
          action={
            <Button
              size='small'
              type='text'
              onClick={() => {
                setShowHiddenMessage(true);
              }}
              danger
            >
              {ICONS.closeCircle} Close
            </Button>
          }
          banner
        />
      )}

      <main
        id='runaway-container'
        className={`fixed top-0 left-0 w-screen h-screen bg-white z-50 flex items-center justify-center ${
          showHiddenMessage ? '' : 'hidden'
        }`}
      >
        <div className='flex items-center justify-center flex-col w-full h-full relative'>
          <h1 className='mb-2'>
            <span className='text-5xl'>😿</span>
          </h1>
          <p className='mt-2 text-lg w-1/3 text-center'>
            Stvarno li si mislila da možeš da izbegneš obaveze tako što ćeš samo
            da ugasiš poruku?
          </p>
          <div className='flex items-center'>
            <button
              onClick={() => {
                alert('Bravo! Idi pročitaj knjigu.');
                setShowHiddenMessage(false);
              }}
              className='px-3 py-2 bg-slate-200 border-none mr-2 cursor-pointer text-lg'
            >
              Ne
            </button>
            <button
              id='runaway'
              className='px-3 block py-2 bg-green-400 text-white border-none cursor-pointer  text-lg'
            >
              Da
            </button>
          </div>
        </div>
      </main>

      <Header
        className='bg-white '
        style={{
          padding: '0 20px',
          background: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {/* <div className='mr-2'>
          <StatusSwitch
            status={status}
            setStatus={setStatus}
          />
        </div> */}
        <div>
          <Dropdown
            menu={{ items }}
            trigger={['click']}
          >
            <span
              className='hover:text-blue-400 cursor-pointer'
              onClick={(e) => e.preventDefault()}
            >
              <Space>{user?.data?.name}</Space>
            </span>
          </Dropdown>

          <Avatar
            onClick={() => {
              navigate('/profile');
            }}
            className='ml-2 cursor-pointer'
            style={{
              color: '#f56a00',
              backgroundColor: '#fde3cf',
            }}
          >
            {user?.data?.name[0]}
          </Avatar>
        </div>
      </Header>
    </>
  );
};

export default AppHeader;
