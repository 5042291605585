import React from 'react';
import { Collapse } from 'antd';

const Collapsible = ({ items }) => {
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['0']}
      style={{
        backgroundColor: '#fff',
      }}
      items={items}
    />
  );
};

export default Collapsible;
