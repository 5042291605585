import { Input } from 'antd';
import { store } from 'src';
import ROLES from 'src/common/enums/roles';
import Utils from 'src/common/Utils';

const roles = [
  {
    label: 'Superadmin',
    value: ROLES.Superadmin,
  },
  {
    label: 'Administrator',
    value: ROLES.Admin,
  },
  {
    label: 'Developer',
    value: ROLES.Developer,
  },
];

export const mainFields = ({ headerTitle }) => {
  const user = store.getState().auth?.user;
  const allowedRoles = [...roles];
  if (user?.data.role !== ROLES.Superadmin) {
    allowedRoles.shift();
  }
  return [
    ...Utils.createHeader({ title: headerTitle }),
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-3',
      options: {
        label: 'Name',
        name: 'name',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input user name!',
          },
        ],
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-3',
      options: {
        label: 'Email',
        name: 'email',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input user email!',
          },
        ],
      },
    },
    {
      controlType: <Input.Password />,
      colSize: 'col-span-12 sm:col-start-1 sm:col-end-4',
      options: {
        label: 'Password',
        name: 'password',
      },
    },
    {
      controlType: <Input.Password />,
      colSize: 'col-span-12 sm:col-start-4 sm:col-end-7',
      options: {
        label: 'Password confirmation',
        name: 'password_confirmation',
      },
    },
  ];
};
