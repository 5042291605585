import { Alert } from 'antd';
import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert
          message='Something went wrong.'
          description='Please try again or contact support.'
          type='error'
          showIcon
          className='mb-2'
        />
      );
    }

    return this.props.children;
  }
}
