import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from 'src/common/actions/modal';
import { ICONS } from 'src/common/Icons';
import MainButton from 'src/components/buttons/MainButton';
import Table from 'src/components/Table';
import { tableColumns } from './licenses-fields';
import LicensesService from 'src/common/api/LicensesService';
import LicensesAdd from './LicensesAdd';
import LicensesEdit from './LicensesEdit';
import moment from 'moment';

const LicensesList = () => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async (query = '') => {
    let response = await LicensesService.all({ query });
    const res = response;
    res.data = res.data.map((item) => {
      return {
        ...item,
        lease_start: moment(item.lease_start).format('YYYY-MM-DD'),
        lease_end: moment(item.lease_end).format('YYYY-MM-DD'),
      };
    });
    setData(res);
  };

  const onCreate = () => {
    dispatch(
      openModal({
        title: 'Create license',
        component: <LicensesAdd onSuccess={getData} />,
      })
    );
  };

  const openEditModal = ({ id }) => {
    dispatch(
      openModal({
        title: 'Edit license',
        component: (
          <LicensesEdit
            id={id}
            onSuccess={getData}
          />
        ),
      })
    );
  };

  const onDelete = ({ id }) => {
    LicensesService.delete(id).then(() => {
      getData();
    });
  };

  return (
    <div>
      <div className='flex justify-between items-center flex-wrap mb-2'>
        <h2 className='font-normal'>Licenses</h2>
        <MainButton
          label='Create'
          onClick={onCreate}
          icon={ICONS.add}
        />
      </div>

      <Table
        data={data}
        columns={tableColumns}
        onFilter={(query) => getData(query)}
        onEdit={(data) => openEditModal(data)}
        onDelete={(data) => onDelete(data)}
      />
    </div>
  );
};

export default LicensesList;
