import { useState } from "react";
import { Switch } from "antd";
import { toggleKpiCompletion } from "src/common/api/KpiService";

const GradeSwitch = ({ initialChecked, id, disabled }) => {
  const [checked, setChecked] = useState(initialChecked);

  return (
    <Switch
      {...(initialChecked ? { defaultChecked: true } : {})}
      onChange={() => {
        setChecked(!checked);
        toggleKpiCompletion(id);
      }}
      style={{
        background: checked ? "#B4FF00" : "#132038",
        color: checked ? "#132038" : "#B4FF00",
      }}
      disabled={disabled}
    />
  );
};

export default GradeSwitch;
