import Utils from '../Utils';

const ROLES = {
  Superadmin: 1,
  Admin: 2,
  Developer: 3,
  all: () => {
    return Utils.getObjectKeyValue(ROLES)[1];
  },
};

export default ROLES;
