import React, { useEffect } from 'react';
import FormFactory from 'src/components/FormFactory';
import { resetPasswordFields } from './login-fields';
import logo from 'src/assets/main-logo-dark.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import axios from 'axios';
import config from 'src/config/config';
import Toast from 'src/common/Toast';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue({
      token: searchParams.get('token'),
      email: searchParams.get('email'),
    });
  }, []);

  const onSubmit = async (creds) => {
    await axios
      .post(`${config.baseUrl}/reset-password`, creds)
      .then(() => {
        navigate('/login');
        Toast.success('You have successfully changed your password');
      })
      .catch((error) => {
        Toast.error(error?.response?.data?.message);
      });
  };

  return (
    <main className='flex h-screen w-screen justify-center items-center bg-[#F7F8FC]'>
      <section className='h-fit flex-col w-full sm:w-[390px] mx-auto '>
        <div className='flex items-center mb-6'>
          <img className='w-[200px] mx-auto' src={logo} alt='ITCentar' />
        </div>
        <div className='bg-white p-7 m-5 sm:m-0 rounded-lg '>
          <FormFactory
            form={form}
            onFinish={(form) => onSubmit(form)}
            fields={resetPasswordFields}
          />
        </div>
      </section>
    </main>
  );
};

export default ResetPassword;
