import { Checkbox, DatePicker, Input, Select, Switch } from 'antd';
import { colorOptions } from 'src/common/Static';
import Utils from 'src/common/Utils';
import { ICONS } from 'src/common/Icons';

const { Option } = Select;
const { TextArea } = Input;

export const tableColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'id',
  },
  {
    title: 'Website URL',
    dataIndex: ['url'],
    key: ['url'],
    render: (_, e) => {
      return (
        <a
          href={e['url']}
          className='text-xs bg-blue-50 text-blue-500 py-1 px-2 flex items-center justify-center rounded-full'
          target='_blank'
        >
          <span className='mr-1 block'>Open</span>
          {ICONS.link}
        </a>
      );
    },
  },
  {
    title: 'Client',
    dataIndex: ['client', 'name'],
    key: ['client', 'name'],
  },
  {
    title: 'Is expired',
    dataIndex: 'is_expired',
    key: 'is_expired',
    render: (_, e) => {
      return e['is_expired'] ? (
        <div className='text-red-500 bg-red-50 text-xs p-1 px-2 rounded-full'>Expired</div>
      ) : null;
    },
  },
  {
    title: 'Lease start',
    dataIndex: 'lease_start',
    key: 'lease_start',
  },
  {
    title: 'Lease end',
    dataIndex: 'lease_end',
    key: 'lease_end',
  },
];

export const filterFields = [
  {
    controlType: <Input allowClear />,
    colSize: 'col-span-12 sm:col-span-3',
    options: {
      label: 'Search',
      name: 'search',
      value: null,
    },
  },
];

export const mainFields = ({ headerTitle, clientsDropdown, projectsDropdown }) => {
  return [
    ...Utils.createHeader({ title: headerTitle }),
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Title',
        name: 'title',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input project title!',
          },
        ],
      },
    },
    {
      controlType: (
        <Select optionFilterProp='children' showSearch={true}>
          {clientsDropdown.map((option) => {
            return (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Client',
        name: 'client_id',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please choose client!',
          },
        ],
      },
    },
    {
      controlType: (
        <Select optionFilterProp='children' showSearch={true}>
          {projectsDropdown.map((option) => {
            return (
              <Option key={option.id} value={option.id}>
                {option.title}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Project',
        name: 'project_id',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please choose project!',
          },
        ],
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Price',
        name: 'price',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-8',
      options: {
        label: 'URL',
        name: 'url',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 ',
      options: {
        label: 'Notify emails (Enter with comma after each)',
        name: 'notify_emails',
        value: null,
      },
    },
    {
      controlType: <DatePicker className='w-full' format={'DD.MM.YYYY'} clearIcon={true} />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Lease start',
        name: 'lease_start',
        value: null,
      },
    },
    {
      controlType: <DatePicker className='w-full' format={'DD.MM.YYYY'} clearIcon={true} />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Lease end',
        name: 'lease_end',
        value: null,
      },
    },
    {
      controlType: <TextArea rows={3} />,
      colSize: 'col-span-12',
      options: {
        label: 'Description',
        name: 'description',
        value: null,
      },
    },
    {
      controlType: <Switch />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Is paid',
        name: 'is_paid',
        valuePropName: 'checked',
      },
    },
  ];
};
