import {
  UPDATE_TIMER,
  START_TIMER,
  STOP_TIMER,
  RESET_TIMER,
} from '../actions/timer';

export default function timer(
  state = {
    active: true,
    time: '00:00:00',
    stopped: false,
  },
  action
) {
  switch (action.type) {
    case START_TIMER:
      return Object.assign({}, state, {
        active: true,
        time: '00:00:00',
        stopped: false,
      });
    case UPDATE_TIMER:
      return Object.assign({}, state, {
        active: true,
        time: action.time,
        stopped: false,
      });
    case STOP_TIMER:
      return Object.assign({}, state, {
        active: false,
        time: '00:00:00',
        stopped: true,
      });
    case RESET_TIMER:
      return Object.assign({}, state, {
        active: false,
        time: '00:00:00',
        stopped: false,
      });
    default:
      return state;
  }
}
