import { store } from 'src';
import { startLoader, stopLoader } from 'src/common/actions/loader';
import { axios } from '../../config/axios';

const KpiUserService = {
  async getAll(params) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.get(`/kpis/users?${params}`);
      store.dispatch(stopLoader());
    } catch (err) {
      store.dispatch(stopLoader());
    }
    return response;
  },
  async getOne(id, params) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.get(`/kpis/user/${id}?${params}`);
      store.dispatch(stopLoader());
    } catch (err) {
      store.dispatch(stopLoader());
    }
    return response;
  },
};

export default KpiUserService;
