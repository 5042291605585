import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "src/assets/main-logo-light.svg";
import { getSidebarRoutes } from "src/config/sidebar-routes";
import Utils from "src/common/Utils";

const { Sider } = Layout;
const AppSidebar = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [time, setTime] = useState("");
  const [sidebarRoutes, setSidebarRoutes] = useState([]);
  const [user, setUser] = useState(null);

  const onNavigate = (e) => {
    navigate(e.key);
  };

  useEffect(() => {
    const unsubscribe = Utils.observeStore(({ timer, auth }) => {
      setUser(auth.user);
      if (user?.token !== auth?.user?.token) {
        filterAllowedRoutes(auth?.user?.data.role_id);
      }
      setTime(timer?.time);
    });

    return () => {
      unsubscribe();
    };
  }, [user, sidebarRoutes]);

  const filterAllowedRoutes = (roleID) => {
    const routes = getSidebarRoutes().filter((route) => {
      if (route?.roles?.includes(roleID)) {
        if (route?.children) {
          route.children = route?.children?.filter((childrenRoute) => {
            if (childrenRoute.roles?.includes(roleID)) {
              return childrenRoute;
            }
          });
        }
        return route;
      }
    });

    setSidebarRoutes(routes);
  };

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      style={{
        backgroundColor: "#132038",
      }}
      width={250}
    >
      <div className="ml-2 mx-auto my-4 mb-9 flex justify-between items-center mr-4">
        <div className="flex items-center justify-center w-full mt-2 flex-col">
          <img src={logo} alt="Logo" className="w-[120px]" />
          <div className="bg-gradient-to-r from-[#2A364C] to-[#132038] p-3 rounded-md w-[80%] flex items-center justify-center mt-7">
            <h3 className="font-normal text-white p-0 m-0 text-lg tracking-wider">
              {time}
            </h3>
          </div>
        </div>
        {/* {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          }
        )} */}
      </div>

      {sidebarRoutes && (
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          onClick={onNavigate}
          items={sidebarRoutes}
          style={{
            backgroundColor: "#132038",
          }}
        />
      )}
    </Sider>
  );
};

export default AppSidebar;
