import { store } from 'src';
import { startLoader, stopLoader } from 'src/common/actions/loader';
import { axios } from 'src/config/axios';
import config from 'src/config/config';
import ApiFactory from './ApiFactory';

const TasksService = {
  ...ApiFactory({ endpoint: 'tasks' }),
  async allForProject(id) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.get(`/projects/${id}/tasks`);
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
  async allWithoutPagination(id) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.get(`/projects/${id}/tasks/all`);
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
};

export default TasksService;
