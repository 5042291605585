import { Select, Tag, Input } from 'antd';
import { KpiTypes } from 'src/common/enums/kpi';
import { kpiTypes } from 'src/common/constants/kpi';
import Utils from 'src/common/Utils';

const { Option } = Select;

export const tableColumns = [
  {
    title: 'Name',
    dataIndex: 'title',
    key: 'id',
    render: (_, { title, description }) => {
      return (
        <div>
          <p className='leading-none font-bold text-md'>{title}</p>
          <p className='leading-none font-light text-sm'>{description}</p>
        </div>
      );
    },
  },
  {
    title: 'Type',
    dataIndex: 'kpi_type',
    key: 'kpi_type',
    responsive: ['sm'],
    render: (_, { kpi_type, kpi_type_name }) => {
      switch (kpi_type) {
        case KpiTypes.PERSONAL:
          return <Tag color='cyan'>{kpi_type_name}</Tag>;
        case KpiTypes.TEAM:
          return <Tag color='red'>{kpi_type_name}</Tag>;
        case KpiTypes.BONUS:
          return <Tag color='green'>{kpi_type_name}</Tag>;
        case KpiTypes.ORGANIZATIONAL:
          return <Tag color='volcano'>{kpi_type_name}</Tag>;
      }
    },
  },
  {
    title: 'Expected grade',
    dataIndex: 'expected_grade',
    key: 'expected_grade',
    responsive: ['sm'],
  },
];

export const filterFields = () => {
  return [
    {
      controlType: (
        <Select
          mode='single'
          maxTagCount='responsive'
          optionFilterProp='children'
          showSearch={true}
        >
          {kpiTypes?.map((option) => {
            return (
              <Option
                key={option.label}
                value={option.value}
              >
                {option.label}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-6 sm:col-span-3',
      options: {
        label: 'Type',
        name: 'kpi_type',
        value: null,
      },
    },
  ];
};

export const mainFields = (headerTitle) => {
  return [
    ...Utils.createHeader({ title: headerTitle }),
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-8',
      options: {
        label: 'Name',
        name: 'title',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the name of the KPI',
          },
        ],
      },
    },
    {
      controlType: (
        <Select>
          {kpiTypes.map((type) => {
            return (
              <Option
                key={type.value}
                value={type.value}
              >
                {type.label}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'KPI type',
        name: 'kpi_type',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the KPI type',
          },
        ],
      },
    },
    {
      controlType: <Input.TextArea />,
      colSize: 'col-span-12 sm:col-span-8',
      options: {
        label: 'Description',
        name: 'description',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the KPI description',
          },
        ],
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Expected grade',
        name: 'expected_grade',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input the KPI expected grade',
          },
        ],
      },
    },
  ];
};
