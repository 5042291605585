import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import KpiTemplatesService from 'src/common/api/KpiTemplatesService';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './kpi-templates-fields';
import { useNavigate, useParams } from 'react-router';

const KpiTemplatesEdit = () => {
  const [formFields, setFormFields] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await KpiTemplatesService.one(id);

    form.setFieldsValue({
      ...response.data,
      kpi_type: response.data.kpi_type,
    });
    setFormFields(mainFields('KPI Template - Edit'));
  };

  const onSubmit = (data) => {
    KpiTemplatesService.update(id, data).then((res) => {
      if (res) {
        form.resetFields();
        navigate('/kpi/templates');
      }
    });
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={formFields}
      />
    </div>
  );
};

export default KpiTemplatesEdit;
