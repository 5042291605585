import { Form } from 'antd';
import React from 'react';
import UsersService from 'src/common/api/UsersService';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './users-fields';

const UsersAdd = ({ onSuccess }) => {
  const [form] = Form.useForm();

  const onSubmit = (data) => {
    UsersService.create(data).then((res) => {
      if (!res.status) {
        onSuccess();
        form.resetFields();
      }
    });
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={mainFields({})}
      />
    </div>
  );
};

export default UsersAdd;
