import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from 'src/common/actions/modal';
import TasksService from 'src/common/api/TasksService';
import { ICONS } from 'src/common/Icons';
import MainButton from 'src/components/buttons/MainButton';
import Table from 'src/components/Table';
import { tableColumns } from './tasks-fields';
import TasksAdd from './TasksAdd';
import TasksEdit from './TasksEdit';

const TasksList = ({ projectId }) => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let response = await TasksService.allWithoutPagination(projectId);
    setData(response);
  };

  const onCreate = (id) => {
    dispatch(
      openModal({
        title: 'Create task',
        component: (
          <TasksAdd
            projectId={id}
            onSuccess={getData}
          />
        ),
      })
    );
  };

  const openEditModal = ({ id }, projectId) => {
    dispatch(
      openModal({
        title: 'Edit task',
        component: (
          <TasksEdit
            id={id}
            projectId={projectId}
            onSuccess={getData}
          />
        ),
      })
    );
  };

  const onDelete = ({ id }) => {
    TasksService.delete(id).then(() => {
      getData();
    });
  };

  return (
    <div className='mt-3'>
      <div className='flex justify-between items-center flex-wrap mb-2'>
        <h2 className='font-normal '>Tasks </h2>
        <MainButton
          label='Create'
          onClick={() => {
            onCreate(projectId);
          }}
          icon={ICONS.add}
        />
      </div>

      <Table
        data={data}
        columns={tableColumns}
        onFilter={(query) => getData(query)}
        onEdit={(data) => openEditModal(data, projectId)}
        onDelete={(data) => onDelete(data)}
      />
    </div>
  );
};

export default TasksList;
