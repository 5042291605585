import { Select, Input } from 'antd';
import Utils from 'src/common/Utils';

const { Option } = Select;

export const tableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'id',
  },
  {
    title: 'KPI',
    dataIndex: 'kpiCount',
    key: 'kpiCount',
    responsive: ['sm'],
    render: (_, { kpiCompletedCount, kpiCount }) => (
      <div>
        {kpiCompletedCount}/{kpiCount}
      </div>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'kpiCompletedPercent',
    key: 'kpiCompoletedPercent',
    responsive: ['sm'],
    render: (_, { kpiCompletedPercent }) => <div>{kpiCompletedPercent}%</div>,
  },
];

const filterData = {
  quarters: [
    { title: 'Q1', id: 0 },
    { title: 'Q2', id: 1 },
    { title: 'Q3', id: 2 },
    { title: 'Q4', id: 3 },
  ],
  year: Utils.generateYearObjects(2023),
};

export const filterFields = () => {
  return [
    {
      controlType: <Input allowClear />,
      colSize: 'col-span-6 sm:col-span-3',
      options: {
        label: 'Name',
        name: 'search',
        value: null,
      },
    },
    {
      controlType: (
        <Select
          mode='single'
          maxTagCount='responsive'
          optionFilterProp='children'
          showSearch={true}
          defaultValue={Utils.getCurrentQuarter()}
        >
          {filterData.quarters?.map((option, index) => {
            return (
              <Option
                key={index}
                value={option.title}
              >
                {option.title}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-6  sm:col-span-3',
      options: {
        label: 'Quarter',
        name: 'quarter',
        value: null,
      },
    },
    {
      controlType: (
        <Select
          mode='single'
          maxTagCount='responsive'
          optionFilterProp='children'
          showSearch={true}
          defaultValue={filterData.year[0]?.year}
        >
          {filterData.year?.map((option, index) => {
            return (
              <Option
                key={index}
                value={option.year}
              >
                {option.year}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-6  sm:col-span-3',
      options: {
        label: 'Year',
        name: 'year',
        value: null,
      },
    },
  ];
};
