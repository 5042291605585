export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const openModal = ({ title, component }) => {
  return {
    type: OPEN_MODAL,
    opened: true,
    title: title,
    component: component,
  };
};

export const closeModal = () => {
  return {
    type: CLOSE_MODAL,
    opened: false,
  };
};
