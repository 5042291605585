import { Form } from 'antd';
import React, { useEffect } from 'react';
import TasksService from 'src/common/api/TasksService';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './tasks-fields';
import { closeModal } from 'src/common/actions/modal';
import { useDispatch } from 'react-redux';

const TasksEdit = ({ id, onSuccess, projectId }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    getData(id);
  }, [id]);

  const getData = async (id) => {
    let { data } = await TasksService.one(id);
    form.setFieldsValue(data);
  };

  const onSubmit = (data) => {
    TasksService.update(id, data).then(() => {
      onSuccess();
      dispatch(closeModal());
    });
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={mainFields(projectId)}
      />
    </div>
  );
};

export default TasksEdit;
