export const START_TIMER = 'START_TIMER';
export const UPDATE_TIMER = 'UPDATE_TIMER';
export const STOP_TIMER = 'STOP_TIMER';
export const RESET_TIMER = 'RESET_TIMER';

export const startTimer = () => {
  return {
    type: START_TIMER,
    active: true,
    stopped: false,
    time: '00:00:00',
  };
};

export const updateTimer = (time) => {
  return {
    type: UPDATE_TIMER,
    active: true,
    stopped: false,
    time,
  };
};

export const stopTimer = () => {
  return {
    type: STOP_TIMER,
    active: false,
    stopped: true,
    time: '00:00:00',
  };
};

export const resetTimer = () => {
  return {
    type: STOP_TIMER,
    active: false,
    stopped: false,
    time: '00:00:00',
  };
};
