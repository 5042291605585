import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import { Outlet, useLocation } from 'react-router';
import Modal from '../Modal';
import ErrorBoundary from '../ErrorBoundary';
import Tracker from 'src/pages/time-tracker/tracker/Tracker';
const { Content } = Layout;

const AppLayout = () => {
  let location = useLocation();
  const [showTracker, setShowTracker] = useState(false);

  useEffect(() => {
    if (location.pathname === '/tracker') {
      setShowTracker(true);
    } else {
      setShowTracker(false);
    }
  }, [location, showTracker]);

  return (
    <Layout style={{ height: '100vh', overflow: 'hidden' }}>
      <AppSidebar />
      <Layout className='site-layout'>
        <AppHeader />
        <div
          className={`px-[30px] py-[15px] bg-[#F7F8FC] ${
            showTracker ? 'block' : 'hidden'
          }`}
        >
          <ErrorBoundary>
            <Tracker getAllTrackedTimes={() => {}} />
          </ErrorBoundary>
        </div>
        <Content
          style={{
            padding: 30,
            minHeight: 280,
            overflowX: 'hidden',
            overflowY: 'auto',
            background: '#F7F8FC',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      <Modal />
    </Layout>
  );
};

export default AppLayout;
