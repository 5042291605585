import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { closeModal } from 'src/common/actions/modal';
import { useDispatch } from 'react-redux';

const KpiCreateModal = ({ user, quarter }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className='flex justify-around'>
      <Button
        onClick={() => {
          dispatch(closeModal());
          navigate('/kpi/templates', {
            state: {
              mode: 'template',
              user,
              quarter,
            },
          });
        }}
      >
        Create from template
      </Button>
      <Button
        onClick={() => {
          dispatch(closeModal());
          navigate('/kpi/create', {
            state: {
              mode: 'new',
              user,
              quarter,
            },
          });
        }}
      >
        Create brand new
      </Button>
    </div>
  );
};

export default KpiCreateModal;
