import { useEffect, useState, useRef } from "react";
import { Divider, Input, theme } from "antd";
import KpiService from "src/common/api/KpiService";
import { useParams } from "react-router";
import Collapsible from "src/components/Collapsible";
import MainButton from "src/components/buttons/MainButton";
import Utils from "src/common/Utils";
import { useNavigate } from "react-router";
import GradeSwitch from "src/components/buttons/GradeSwitch";
import ROLES from "src/common/enums/roles";
import { store } from "src";

const KpiEdit = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const user = store.getState().auth?.user?.data;
  const isAdmin = [ROLES.Superadmin, ROLES.Admin].includes(user?.role_id);

  const refsArray = useRef([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await KpiService.one(id);

      setData(response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    const noteData = data.note.map((item, index) => ({
      ...item,
      personal_grade:
        refsArray.current[index]?.personalGradeRef?.current?.resizableTextArea
          ?.textArea?.value ?? item?.personal_grade,
      team_lead_grade:
        refsArray.current[index]?.teamLeadGradeRef?.current?.resizableTextArea
          ?.textArea?.value ?? item?.team_lead_grade,
    }));

    const formData = {
      user_id: data?.user?.id,
      kpi_template_id: data?.kpi_template?.id,
      year: data?.year,
      quarter: data?.quarter,
      grade: data?.grade,
      note: noteData,
    };

    await KpiService.update(data?.id, formData);
    navigate(`/kpi/users/${data?.user?.id}`);
  };

  const CollapsibleItemChildren = ({ item, index }) => {
    refsArray.current[index] = {
      personalGradeRef: useRef(null),
      teamLeadGradeRef: useRef(null),
    };

    return (
      <div className="flex flex-row space-x-2">
        <div className="w-full">
          <p className="text-sm">Personal grade</p>
          <Input.TextArea
            ref={refsArray.current[index].personalGradeRef}
            defaultValue={item?.personal_grade ? item?.personal_grade : ""}
            rows={6}
          />
        </div>
        <div className="w-full">
          <p className="text-sm">Team lead's grade</p>
          <Input.TextArea
            ref={refsArray.current[index].teamLeadGradeRef}
            defaultValue={item?.team_lead_grade ? item?.team_lead_grade : ""}
            rows={6}
            disabled={!isAdmin}
          />
        </div>
      </div>
    );
  };

  const collapsibleItems = data?.note?.map((item, index) => ({
    key: index,
    label: item?.month,
    children: (
      <CollapsibleItemChildren
        item={item}
        index={index}
      />
    ),
    style: {
      marginBottom: 24,
      background: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      border: "none",
    },
  }));

  return (
    <div>
      <div className="flex justify-between items-center flex-wrap mb-2">
        <h2 className="leading-none font-normal ">
          {data?.kpi_template?.title}
        </h2>
        <div className="flex flex-row space-x-2">
          <MainButton
            label="Save"
            onClick={handleSave}
          />
        </div>
      </div>
      <div className="h-screen bg-white p-6">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row space-x-8 items-center">
            <div className="flex flex-row items-center space-x-2">
              <div className="text-md font-light">Type:</div>
              {Utils.renderKpiType(
                data?.kpi_template?.kpi_type,
                data?.kpi_template?.kpi_type_name
              )}
            </div>
            <div className="flex flex-row space-x-2">
              <div className="text-md font-light">Expected grade:</div>
              <div className="text-md font-semibold">
                {data?.kpi_template?.expected_grade}
              </div>
            </div>
          </div>
          <div className="flex flex-row space-x-2 items-center">
            <div className="text-md font-light">Completed:</div>
            {!isLoading && (
              <GradeSwitch
                initialChecked={data?.completed}
                id={id}
                disabled={!isAdmin}
              />
            )}
          </div>
        </div>
        <Divider />
        <div className="text-md mb-10">{data?.kpi_template?.description}</div>
        <Collapsible items={data?.note?.length === 0 ? [] : collapsibleItems} />
      </div>
    </div>
  );
};

export default KpiEdit;
