import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router';
import { store } from 'src';
import ROLES from 'src/common/enums/roles';
import config from 'src/config/config';
import ROUTES from 'src/config/routes';
import Login from 'src/pages/login/Login';
import RequestPasswordReset from 'src/pages/login/RequestPasswordReset';
import ResetPassword from 'src/pages/login/ResetPassword';
import Page403 from 'src/pages/page403/Page403';
import Page404 from 'src/pages/page404/Page404';
import AppLayout from './layout/AppLayout';
import LoginLayout from './layout/LoginLayout';

function PrivateRoute({ label, allowedRoles, children }) {
  const { auth } = useSelector((state) => state);
  const userRoleID = store.getState().auth?.user?.data?.role_id;
  const isAllowed = allowedRoles.includes(userRoleID) && auth.isAuthenticated;

  if (!config.auth) {
    return children;
  }

  if (!allowedRoles.includes(userRoleID) && userRoleID) {
    return <Navigate to='/403' />;
  }

  return isAllowed ? children : <Navigate to='/login' />;
}

function PublicRoute({ children }) {
  const { auth } = useSelector((state) => state);
  return auth.isAuthenticated ? <Navigate to='/tracker' /> : children;
}

const Router = () => {
  return (
    <>
      <Routes>
        <Route element={<LoginLayout />}>
          <Route
            path='/login'
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path='/request-password-reset'
            element={
              <PublicRoute>
                <RequestPasswordReset />
              </PublicRoute>
            }
          />
          <Route
            path='/reset-password'
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
        </Route>
        <Route element={<AppLayout />}>
          <Route path='*' element={<Navigate to='/404' />} />
          <Route index element={<Navigate to='/tracker' />} />
          {ROUTES.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <PrivateRoute
                    allowedRoles={route.allowedRoles}
                    label={route.label}
                  >
                    {route.component}
                  </PrivateRoute>
                }
              />
            );
          })}

          <Route
            path='/404'
            element={
              <PrivateRoute allowedRoles={ROLES.all()} label='404'>
                <Page404 />
              </PrivateRoute>
            }
          />

          <Route
            path='/403'
            element={
              <PrivateRoute allowedRoles={ROLES.all()} label='404'>
                <Page403 />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default Router;
