export const colorOptions = [
  {
    value: "#f43f5e",
    label: "Rose",
  },
  {
    value: "#ec4899",
    label: "Pink",
  },
  {
    value: "#d946ef",
    label: "Fuchsia",
  },
  {
    value: "#a855f7",
    label: "Purple",
  },
  {
    value: "#8b5cf6",
    label: "Violet",
  },
  {
    value: "#6366f1",
    label: "Indigo",
  },
  {
    value: "#3b82f6",
    label: "Blue",
  },
  {
    value: "#0ea5e9",
    label: "Sky",
  },
  {
    value: "#06b6d4",
    label: "Cyan",
  },
  {
    value: "#14b8a6",
    label: "Teal",
  },
  {
    value: "#10b981",
    label: "Emerald",
  },
  {
    value: "#22c55e",
    label: "Green",
  },
  {
    value: "#84cc16",
    label: "Lime",
  },
  {
    value: "#eab308",
    label: "Yellow",
  },
  {
    value: "#f97316",
    label: "Orange",
  },
  {
    value: "#ef4444",
    label: "Red",
  },
  {
    value: "#78716c",
    label: "Stone",
  },
];
