import React from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../common/actions/user';
import FormFactory from '../../components/FormFactory';
import { loginFields } from './login-fields';
import logo from 'src/assets/main-logo-dark.svg';
import loginBg from 'src/assets/login-image.png';
import { useNavigate } from 'react-router';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <main className='flex h-screen w-screen justify-center items-center bg-[#F7F8FC]'>
        <section className='h-fit flex-col w-full sm:w-[390px] mx-auto '>
          <div className='flex items-center mb-6'>
            <img className='w-[200px] mx-auto' src={logo} alt='ITCentar' />
          </div>
          <div className='bg-white p-7 m-5 sm:m-0 rounded-lg '>
            <FormFactory
              onFinish={(credentials) => {
                dispatch(loginUser(credentials));
              }}
              fields={loginFields}
            />
            <p
              onClick={() => navigate('/request-password-reset')}
              className='text-center text-sm hover:text-blue-400 cursor-pointer transition-all duration-300'
            >
              Forgot password?
            </p>
          </div>
        </section>
        <img src={loginBg} className='h-full w-auto hidden sm:block' alt='' />
      </main>
    </>
  );
};

export default Login;
