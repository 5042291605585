import React, { useEffect, useState } from "react";
import Utils from "src/common/Utils";
import TimeEntry from "./TimeEntry";

const TimesList = ({ getAllTrackedTimes }) => {
  const [groupList, setGroupList] = useState([]);
  const [projects, setProjects] = useState(null);
  const [trackedTimes, setTrackedTimes] = useState(null);
  const [tags, setTags] = useState(null);

  useEffect(() => {
    const groupTimesByDay = () => {
      const output = trackedTimes?.data.reduce((acc, curr) => {
        const startTime = new Date(curr.start_time).toDateString();
        if (acc[startTime]) {
          acc[startTime].totalTime = acc[startTime].totalTime + curr.total_time;
        }

        acc[startTime]
          ? acc[startTime].items.push(curr)
          : (acc[startTime] = {
              name: new Date(startTime).toDateString(),
              items: [curr],
              totalTime: curr?.total_time,
            });

        return acc;
      }, {});

      const finalArray = [];

      for (const key in output) {
        finalArray.push(output[key]);
      }

      setGroupList(sortByDate(finalArray));
    };

    groupTimesByDay();

    const unsubscribe = Utils.observeStore(
      ({ tags, projects, trackedTimes }) => {
        setTags(tags.tags);
        setProjects(projects.projects);
        setTrackedTimes(trackedTimes.times);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [trackedTimes]);

  const sortByDate = (array) => {
    return array.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.name) - new Date(a.name);
    });
  };
  return (
    <div>
      {groupList &&
        groupList?.map((group) => {
          return (
            <article
              className="mb-4 border border-solid border-[#E3ECF3] rounded-xl"
              key={group.name}
            >
              <div className="flex justify-between items-center bg-[#F7F8FC] px-4 py-3 rounded-xl">
                <span className="text-slate-700 block font-medium">
                  {group.name}
                </span>
                <div className="my-0 flex items-center">
                  <span className="text-slate-300 block text-sm mr-2">
                    Real day total:
                  </span>
                  <span className="text-slate-700 block font-medium text-lg">
                    {Utils.secondsToHoursAndMinutes(group.totalTime).render}
                  </span>
                </div>
              </div>
              {group.items.map((entry) => {
                return (
                  <TimeEntry
                    key={entry.id}
                    projects={projects}
                    tags={tags}
                    entry={entry}
                    onSuccess={getAllTrackedTimes}
                  />
                );
              })}
            </article>
          );
        })}
    </div>
  );
};

export default TimesList;
