import { store } from 'src';
import { startLoader, stopLoader } from 'src/common/actions/loader';
import { axios } from '../../config/axios';

const endpoint = 'user/profile-information';

const ProfileService = {
  async update(data) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.patch(`/${endpoint}`, data);
      store.dispatch(stopLoader());
    } catch (err) {
      console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
  async getInfo() {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.get(`/${endpoint}`);
      store.dispatch(stopLoader());
    } catch (err) {
      console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
};

export default ProfileService;
