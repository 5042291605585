import { store } from 'src';
import { startLoader, stopLoader } from 'src/common/actions/loader';
import config from 'src/config/config';
import { axios } from '../../config/axios';

const endpoint = 'clients';

const MaintenancesService = {
  async all({ page, query, clientId, is_postpaid = 0 }) {
    let response;
    // store.dispatch(startLoader());
    try {
      response = await axios.get(
        `/${endpoint}/${clientId}/maintenances?per_page=10&is_postpaid=${is_postpaid}&page=${page}${
          query || ''
        }`
      );
      // store.dispatch(stopLoader());
    } catch (err) {
      console.log('ERROR', err);
      // store.dispatch(stopLoader());
    }
    return response;
  },
  async allTimeRecords({ page, query, maintenanceId, isPaid }) {
    let response;
    // store.dispatch(startLoader());
    try {
      response = await axios.get(
        `/maintenances/${maintenanceId}/time-records?per_page=10&is_paid=${
          isPaid ? 1 : 0
        }&page=${page}${query || ''}`
      );
      // store.dispatch(stopLoader());
    } catch (err) {
      console.log('ERROR', err);
      // store.dispatch(stopLoader());
    }
    return response;
  },
  async allWithoutPagination(clientId) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.get(`/${endpoint}/${clientId}/maintenances/all`);
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
  async one(id) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.get(`/maintenances/${id}`);
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
  async delete(id) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.delete(`/maintenances/${id}`);
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
  async update(id, data) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.patch(`/maintenances/${id}`, data);
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
  async create(data, clientId) {
    let response;
    store.dispatch(startLoader());
    try {
      response = await axios.post(
        `/${endpoint}/${clientId}/maintenances`,
        data
      );
      store.dispatch(stopLoader());
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
      store.dispatch(stopLoader());
    }
    return response;
  },
  async togglePaidStatus(maintenanceId, timeRecordId) {
    let response;
    try {
      response = await axios.patch(
        `/maintenances/${maintenanceId}/time-records/${timeRecordId}/toggle-paid`,
        {}
      );
    } catch (err) {
      if (!config.production) console.log('ERROR', err);
    }
    return response;
  },
};

export default MaintenancesService;
