import { Result } from 'antd';
import React from 'react';

const Page403 = () => {
  return (
    <div className='flex items-center justify-center h-full flex-col'>
      <Result
        status='403'
        title='403'
        subTitle='Sorry, you are not authorized to access this page.'
      />
    </div>
  );
};

export default Page403;
