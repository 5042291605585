import {
  DatePicker,
  Dropdown,
  Form,
  Input,
  Popconfirm,
  Switch,
  Tooltip,
} from 'antd';
import { HomeOutlined, TeamOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import TasksService from 'src/common/api/TasksService';
import Utils from 'src/common/Utils';
import ProjectsDropdown from '../tracker/ProjectsDropdown';
import TagsDropdown from '../tracker/TagsDropdown';
import TasksDropdown from '../tracker/TasksDropdown';
import moment from 'moment';
import dayjs from 'dayjs';
import { ICONS } from 'src/common/Icons';
import TimeRecordsService from 'src/common/api/TimeRecordsService';
import { useDispatch } from 'react-redux';
import { resumeTimer } from 'src/common/actions/resumeTimer';

const TimeEntry = ({ entry, projects, tags, onSuccess }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [tasks, setTasks] = useState(entry?.project_tasks?.tasks);
  const [trackedTime, setTrackedTime] = useState(
    Utils.calculateTrackedTime(Math.abs(entry.total_time))
  );
  const [isWorkFromHome, setIsWorkFromHome] = useState(
    entry?.is_work_from_home
  );

  useEffect(() => {
    form.setFieldsValue({
      description: entry.description,
      id: entry.id,
      project_id: entry.project_id,
      task_id: checkIfIdExsistInArray(tasks, entry.task_id),
      tag_id: entry.tag_id,
      start_hour: moment(entry.start_time, 'YYYY-MM-DD HH:mm:00').format(
        'h:mmA'
      ),
      stop_hour: moment(entry.stop_time, 'YYYY-MM-DD HH:mm:00').format('h:mmA'),
      start_time: moment(entry.start_time),
      stop_time: moment(entry.stop_time),
      date: dayjs(entry.start_time, 'YYYY-MM-DD'),
      total_time: entry.total_time,
      tracked_time: trackedTime,
      is_work_from_home: isWorkFromHome, // Set the is_work_from_home field in the form
    });
    setTrackedTime(Utils.calculateTrackedTime(Math.abs(entry.total_time)));
  }, [entry, tasks, trackedTime, form, projects, isWorkFromHome]);

  const getTasks = async (projectId) => {
    let { data } = await TasksService.allForProject(projectId);
    form.setFieldValue('task_id', null);
    setTasks(data);
  };

  const checkIfIdExsistInArray = (array, ID) => {
    const mappedArray = array?.map((item) => item.id);
    if (mappedArray?.includes(ID)) {
      return ID;
    } else {
      return null;
    }
  };

  const createStartDate = (entryForm) => {
    const startHour = moment(entryForm.start_hour, 'h:mm A');
    const startTime = entryForm.date;

    var splittedDate = {
      year: startTime.get('year'),
      month: startTime.get('month'),
      date: startTime.get('date'),
      hour: startHour.get('hour'),
      minute: startHour.get('minute'),
      second: startHour.get('second'),
      millisecond: startHour.get('millisecond'),
    };

    const finalStartDateTime = moment(entry.start_time);
    finalStartDateTime.set(splittedDate);

    return finalStartDateTime;
  };

  const createStopDate = (entryForm, timeToAdd = null) => {
    const stopHour = moment(entryForm.stop_hour, 'h:mm A');
    const stopTime = entryForm.stop_time;

    const newTime = entryForm.start_time.add(timeToAdd, 'seconds');

    var splittedDate = {
      year: stopTime.get('year'),
      month: stopTime.get('month'),
      date: newTime.get('date'),
      hour: stopHour.get('hour'),
      minute: stopHour.get('minute'),
      second: stopHour.get('second'),
      millisecond: stopHour.get('millisecond'),
    };

    const finalStartDateTime = moment(entry.start_time);
    finalStartDateTime.set(splittedDate);

    return finalStartDateTime;
  };

  const onSave = (e = null, config = null) => {
    const entryForm = form.getFieldValue();
    const timeToAdd = moment.duration(entryForm.tracked_time).asSeconds();

    TimeRecordsService.update(entry.id, {
      ...entryForm,
      start_time: createStartDate(entryForm).format('YYYY-MM-DD HH:mm:00'),
      stop_time: config?.manualHoursChange
        ? createStopDate(entryForm, timeToAdd).format('YYYY-MM-DD HH:mm:00')
        : createStartDate(entryForm)
            .add(timeToAdd, 'seconds')
            .format('YYYY-MM-DD HH:mm:00'),
    }).then(() => {
      onSuccess();
    });
  };

  const onDelete = () => {
    TimeRecordsService.delete(entry.id).then(() => {
      onSuccess();
    });
  };
  return (
    <div className='p-4 bg-white border border-solid border-[#F7F8FC] rounded-xl m-1'>
      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
      >
        <div className='flex gap-4 flex-wrap'>
          <Form.Item
            name='description'
            className='grow mb-0'
          >
            <Input
              id={'description' + entry?.id}
              onBlur={onSave}
              placeholder='What are you working on?'
              className='border-transparent '
            />
          </Form.Item>
          <Form.Item
            name='id'
            hidden={true}
          >
            <Input id={'id' + entry?.id} />
          </Form.Item>
          <ProjectsDropdown
            projects={projects}
            getTasks={getTasks}
            onSave={onSave}
          />
          <TasksDropdown
            form={form}
            tasks={tasks}
            getTasks={getTasks}
            onSave={onSave}
          />
          <TagsDropdown
            tags={tags}
            onSave={onSave}
          />
          <Form.Item
            name={'is_work_from_home'}
            id={'is_work_from_home' + entry?.id}
            valuePropName='checked'
            className='mb-0'
          >
            <Tooltip title={isWorkFromHome ? 'From home' : 'From office'}>
              <Switch
                onChange={(checked) => {
                  form?.setFieldValue('is_work_from_home', checked);
                  setIsWorkFromHome(checked);
                  onSave();
                }}
                checked={isWorkFromHome}
                style={{
                  background: isWorkFromHome ? '#B4FF00' : '#132038',
                  color: isWorkFromHome ? '#132038' : '#B4FF00',
                }}
                checkedChildren={<HomeOutlined className='text-[#132038]' />}
                unCheckedChildren={<TeamOutlined className='text-[#B4FF00]' />}
              />
            </Tooltip>
          </Form.Item>
          <div className='flex items-center border-x border-y-0 border-solid border-slate-200'>
            <Form.Item
              name='start_hour'
              className='w-[77px] mb-0'
            >
              <Input
                id={'start_hour' + entry?.id}
                onBlur={(e) => onSave(e, { manualHoursChange: true })}
                className='border-0 focus:shadow-none focus:border-0 hover:border-0 text-center'
                onPressEnter={onSave}
              />
            </Form.Item>
            <span>-</span>
            <Form.Item
              name='stop_hour'
              className='w-[77px] mb-0'
            >
              <Input
                id={'stop_hour' + entry?.id}
                onBlur={(e) => onSave(e, { manualHoursChange: true })}
                className='border-0 focus:shadow-none focus:border-0 hover:border-0 text-center'
                onPressEnter={onSave}
              />
            </Form.Item>
            <Form.Item
              name='date'
              className='mb-0'
            >
              <DatePicker
                onChange={onSave}
                id={'date' + entry?.id}
                className='w-[10px] border-0  hover:border-none focus:shadow-none cursor-pointer icon-datepicker'
                format={'DD.MM.YYYY'}
                clearIcon={false}
              />
            </Form.Item>
          </div>
          <Form.Item
            name='tracked_time'
            className='w-[70px] mb-0'
          >
            <Input
              id={'tracked_time' + entry?.id}
              onBlur={onSave}
              className='border-0 focus:shadow-none focus:border-0 hover:border-0 text-center text-lg'
              onPressEnter={onSave}
            />
          </Form.Item>
          <div className='flex items-center justify-center'>
            <div
              onClick={() => {
                dispatch(resumeTimer(entry));
              }}
              className='bg-[#ededed] hover:bg-[#dadada]  transition-all duration-300 w-[25px] h-[25px] rounded-full flex items-center justify-center cursor-pointer text-sm'
            >
              {ICONS.start}
            </div>
          </div>
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  key: '4',
                  danger: true,
                  label: (
                    <Popconfirm
                      title='Are you sure you want to delete?'
                      onConfirm={() => onDelete()}
                      okText='Yes'
                      cancelText='No'
                      style={{
                        color: 'red',
                      }}
                    >
                      <div className='w-full h-full'>Delete</div>
                    </Popconfirm>
                  ),
                },
              ],
            }}
          >
            <div className='cursor-pointer flex items-center justify-center'>
              {ICONS.more}
            </div>
          </Dropdown>
        </div>
      </Form>
    </div>
  );
};

export default TimeEntry;
