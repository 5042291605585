import { combineReducers } from 'redux';
import auth from './auth';
import loader from './loader';
import modal from './modal';
import tags from './tags';
import projects from './projects';
import timer from './timer';
import trackedTimes from './trackedTimes';
import resumeTimer from './resumeTimer';

export default combineReducers({
  auth,
  loader,
  modal,
  tags,
  projects,
  timer,
  trackedTimes,
  resumeTimer,
});
