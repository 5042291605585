import { KpiTypes } from '../enums/kpi';

export const kpiTypes = [
  {
    label: 'Team',
    value: KpiTypes.TEAM,
  },
  {
    label: 'Personal',
    value: KpiTypes.PERSONAL,
  },
  {
    label: 'Organizational',
    value: KpiTypes.ORGANIZATIONAL,
  },
  {
    label: 'Bonus',
    value: KpiTypes.BONUS,
  },
];
