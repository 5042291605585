import React from 'react';
import { Steps } from 'antd';

const StepsHeader = ({ currentStep, onChange }) => {
  const steps = [
    {
      status: 'wait',
      title: 'Quarter 1',
      icon: <></>,
    },
    {
      status: 'wait',
      title: 'Quarter 2',
      icon: <></>,
    },
    {
      status: 'wait',
      title: 'Quarter 3',
      icon: <></>,
    },
    {
      status: 'wait',
      title: 'Quarter 4',
      icon: <></>,
    },
  ];

  return (
    <Steps
      type='navigation'
      current={currentStep}
      onChange={onChange}
      className='bg-white'
      items={steps}
    />
  );
};
export default StepsHeader;
