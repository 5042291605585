import { CLOSE_MODAL, OPEN_MODAL } from 'src/common/actions/modal';

export default function loader(
  state = {
    opened: false,
  },
  action
) {
  switch (action.type) {
    case OPEN_MODAL:
      return Object.assign({}, state, {
        opened: true,
        title: action.title,
        component: action.component,
      });
    case CLOSE_MODAL:
      return Object.assign({}, state, {
        opened: false,
      });
    default:
      return state;
  }
}
