import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReportsService from "src/common/api/ReportsService";
import MainButton from "src/components/buttons/MainButton";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import Utils from "src/common/Utils";
import { barChartOptions, pieChartOptions } from "./chartOptions";
import UsersService from "src/common/api/UsersService";
import FormFactory from "src/components/FormFactory";
import { mainFields } from "./filter-fields";
import ProjectsService from "src/common/api/ProjectsService";
import ClientsService from "src/common/api/ClientsService";
import { ICONS } from "src/common/Icons";
import { DatePicker } from "antd";
import config from "src/config/config";
import TimeRecordsService from "src/common/api/TimeRecordsService";
const { RangePicker } = DatePicker;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [filterData, setFilterData] = useState({
    users: [],
    projects: [],
    clients: [],
  });
  const [totalHours, setTotalHours] = useState(0);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    getUsers();
    getProjects();
    getClients();
  }, []);

  useEffect(() => {
    const totalSeconds = data?.pieChart?.datasets?.[0]?.data?.reduce(
      (acc, curr) => Number(acc) + Number(curr),
      0
    );

    setTotalHours(Math.round(totalSeconds / 3600));
  }, [data]);

  const getUsers = async () => {
    let response = await UsersService.allWithoutPagination();
    setFilterData((data) => {
      return { ...data, users: response?.data };
    });
  };

  const getProjects = async () => {
    let response = await ProjectsService.allWithoutPagination();
    setFilterData((data) => {
      return { ...data, projects: response?.data };
    });
  };

  const getClients = async () => {
    let response = await ClientsService.allWithoutPagination();
    setFilterData((data) => {
      return { ...data, clients: response?.data };
    });
  };

  const onExport = async () => {
    let query = "";
    let filterFields = {
      ...form.getFieldsValue(),
    };

    if (dateRange) {
      filterFields = {
        ...filterFields,
        date_from: dateRange[0]?.format(config.dateFormat) || "",
        date_to: dateRange[1]?.format(config.dateFormat) || "",
      };
    }
    query = Utils.createQueryString(filterFields).replace("&", "");

    await TimeRecordsService.export({ query }).then((res) => {
      window.open(res.data, "_blank", "noopener,noreferrer");
    });
  };

  const getData = async () => {
    let query = "";
    let filterFields = {
      ...form.getFieldsValue(),
    };

    if (dateRange) {
      filterFields = {
        ...filterFields,
        date_from: dateRange[0]?.format(config.dateFormat) || "",
        date_to: dateRange[1]?.format(config.dateFormat) || "",
      };
    }
    query = Utils.createQueryString(filterFields).replace("&", "");

    let response = await ReportsService.statistics({ query });
    setData({
      barChart: {
        labels: response?.data?.report_by_days?.days,
        datasets: [
          {
            label: "Times",
            data: response?.data?.report_by_days?.times,
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      },
      pieChart: {
        labels: response?.data?.report_by_projects?.projects,
        datasets: [
          {
            label: "Projects",
            data: response?.data?.report_by_projects?.times,
            backgroundColor: response?.data?.report_by_projects?.colors,
            borderWidth: 1,
          },
        ],
      },
    });
  };

  return (
    <main>
      <div className="bg-white p-4 rounded-sm mb-4 mt-3">
        <div className="flex items-center justify-between flex-wrap-reverse  ">
          <RangePicker
            onChange={(event) => setDateRange(event)}
            className="mb-3"
            format="YYYY-MM-DD"
          />

          <MainButton
            label="Apply"
            type="submit"
            className="w-fit mb-3 sm:mb-0"
            onClick={getData}
            icon={ICONS.save}
          />
        </div>
        <FormFactory
          form={form}
          fields={mainFields(filterData)}
        />
      </div>
      <div className="bg-white p-4 rounded-sm flex flex-col lg:flex-col">
        <div className="flex flex-wrap justify-between items-center mb-3">
          {totalHours >= 0 && (
            <h3 className="font-normal m-0">
              Total hours: {totalHours ?? ""}h
            </h3>
          )}
          <MainButton
            label="Export"
            type="button"
            className="w-fit mb-3 !ml-auto"
            onClick={onExport}
            icon={ICONS.export}
          />
        </div>
        <section className="flex flex-col lg:flex-row lg:justify-evenly justify-center items-center">
          {data && (
            <div className="flex lg:items-center lg:w-[50%] h-full">
              <Bar
                data={data?.barChart}
                options={barChartOptions}
              />
            </div>
          )}
          {data && (
            <div className="flex lg:items-center lg:w-[30%] h-full">
              <Pie
                data={data?.pieChart}
                options={pieChartOptions}
              />
            </div>
          )}
        </section>
      </div>
    </main>
  );
};

export default Dashboard;
