import { useEffect, useState } from 'react';
import { filterFields, tableColumns } from './kpi-fields';
import Table from 'src/components/Table';
import KpiUserService from 'src/common/api/KpiUserService';
import { useParams, useNavigate, useLocation } from 'react-router';
import StepsHeader from 'src/components/StepsHeader';
import Utils from 'src/common/Utils';
import MainButton from 'src/components/buttons/MainButton';
import { ICONS } from 'src/common/Icons';
import UsersService from 'src/common/api/UsersService';
import { openModal } from 'src/common/actions/modal';
import { useDispatch } from 'react-redux';
import KpiCreateModal from 'src/components/modals/KpiCreateModal';
import ROLES from 'src/common/enums/roles';
import { store } from 'src';

const KpiList = () => {
  const location = useLocation();
  const q = location?.state?.quarter;

  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [quarter, setQuarter] = useState(q ? q : Utils.getCurrentQuarter());
  const [currentStep, setCurrentStep] = useState(0);

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
  const currentUser = store.getState().auth?.user?.data;
  const isAdmin = [ROLES.Superadmin, ROLES.Admin].includes(
    currentUser?.role_id
  );

  const onChangeStep = (value) => {
    setCurrentStep(value);
    setQuarter(quarters[value]);
  };

  useEffect(() => {
    getData();
    currentUser?.role_id === ROLES.Developer ? setUser(currentUser) : getUser();
    setCurrentStep(quarters.indexOf(quarter));
  }, [quarter]);

  const getData = async (query = '') => {
    const response = await KpiUserService.getOne(id, query);
    setData(response?.data?.[quarter]?.kpis);
  };

  const getUser = async () => {
    const response = await UsersService.one(id);
    setUser(response?.data);
  };

  const onCreate = () => {
    dispatch(
      openModal({
        title: 'Choose how you want to create KPI',
        component: (
          <KpiCreateModal
            user={user}
            quarter={quarter}
          />
        ),
      })
    );
  };

  return (
    <div>
      <div className='flex justify-between items-center flex-wrap mb-2'>
        <h2 className='font-normal'>{user?.name} - KPI</h2>
        {isAdmin ? (
          <MainButton
            className='bg-primary'
            label='Create'
            onClick={onCreate}
            icon={ICONS.add}
          />
        ) : null}
      </div>
      <StepsHeader
        onChange={onChangeStep}
        currentStep={currentStep}
      />
      <Table
        data={{ data }}
        columns={tableColumns(currentUser?.role_id)}
        filterFields={filterFields}
        onFilter={(query) => {
          getData(query);
        }}
        onEdit={(data) => navigate(`/kpi/${data?.id}`)}
        hideDelete={true}
      />
    </div>
  );
};

export default KpiList;
