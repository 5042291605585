import { Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resumeTimer } from 'src/common/actions/resumeTimer';
import { resetTimer, stopTimer, updateTimer } from 'src/common/actions/timer';
import { setTrackedTimes } from 'src/common/actions/trackedTimes';
import TimeRecordsService from 'src/common/api/TimeRecordsService';
import TimeTrackerService from 'src/common/api/TimeTrackerService';
import { ICONS } from 'src/common/Icons';
import Utils from 'src/common/Utils';

const StopWatch = ({
  form,
  currentTime,
  setCurrentRunningTime,
  setIsWorkFromHome,
}) => {
  const dispatch = useDispatch();
  const [timerStarted, setTimerStarted] = useState(
    currentTime !== 0 ? true : false
  );
  const [time, setTime] = useState(currentTime);

  const onStartTimer = async () => {
    const data = form.getFieldsValue();
    if (currentTime !== 0) {
      setTimerStarted(true);
      return;
    }
    await TimeTrackerService.start(data).then((res) => {
      if (res) {
        setTimerStarted(true);
        setCurrentRunningTime({ time_spent: 1 });
      }
    });
  };

  const onResumeTimer = async (data) => {
    dispatch(resumeTimer(null));
    form.setFieldsValue(data);
    if (currentTime !== 0) {
      setTimerStarted(true);
      return;
    }
    await TimeTrackerService.start(data).then((res) => {
      if (res) {
        setTimerStarted(true);
        setCurrentRunningTime({ time_spent: 1 });
      }
    });
  };

  const getAllTrackedTimes = async (page = 1) => {
    let response = await TimeRecordsService.all({ page });
    // setData(response);
    dispatch(setTrackedTimes(response));
  };

  const onStopTimer = async () => {
    const timerForm = form.getFieldsValue();
    await TimeTrackerService.stop(timerForm).then((res) => {
      if (res) {
        setTime(0);
        setCurrentRunningTime({ time_spent: 0 });
        setTimerStarted(false);
        getAllTrackedTimes();
        dispatch(stopTimer());
        dispatch(resetTimer());
        form.resetFields();
        //videti zasto resetFields ne resetuje work from home
        form?.setFieldValue('is_work_from_home', false);
        setIsWorkFromHome(false);
        document.title = `ITC • Tracker`;
      }
    });
  };

  const onCancelTimer = async () => {
    await TimeTrackerService.cancel().then(() => {
      setTime(0);
      setCurrentRunningTime({ time_spent: 0 });
      setTimerStarted(false);
      dispatch(stopTimer());
      document.title = `ITC • Tracker`;
    });
  };

  useEffect(() => {
    let interval = null;
    let currentTime = 0;

    if (timerStarted) {
      interval = setInterval(() => {
        setTime((prev) => {
          currentTime = prev + 1;
          return prev + 1;
        });
        // Update redux
        const { obj, render } = Utils.secondsToTime(currentTime + 1);
        if (render.props.children[4] !== null) {
          dispatch(updateTimer(render));
          document.title = `${obj.h}:${obj.m}:${obj.s} • Tracker`;
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }

    const unsubscribe = Utils.observeStore(({ resumeTimer }) => {
      const timerForResume = resumeTimer.data;
      if (timerForResume?.id) onResumeTimer(timerForResume);
    });

    return () => {
      clearInterval(interval);
      unsubscribe();
    };
  }, [timerStarted, dispatch]);

  return (
    <>
      <div className='col-start-10 col-end-13 flex items-center justify-end'>
        <div className='mr-4 flex items-center justify-center text-lg'>
          {Utils.secondsToTime(time).render}
        </div>
        {timerStarted ? (
          <div
            onClick={() => onStopTimer()}
            className='bg-[#F7F8FC] group hover:bg-red-500 hover:text-[#F7F8FC] transition-all duration-300 w-[35px] h-[35px] rounded-full flex items-center justify-center cursor-pointer text-md'
            data-testid='stop-button'
          >
            {ICONS.stop}
          </div>
        ) : (
          <div
            onClick={() => onStartTimer()}
            className='bg-[#B4FF00] hover:bg-[#132038] hover:text-[#B4FF00] transition-all duration-300 w-[35px] h-[35px] rounded-full flex items-center justify-center cursor-pointer text-lg'
            data-testid='play-button'
          >
            {ICONS.start}
          </div>
        )}

        {timerStarted && (
          <Popconfirm
            title='Are you sure you want to cancel timer?'
            onConfirm={onCancelTimer}
            okText='Yes'
            cancelText='No'
            style={{
              color: 'red',
            }}
          >
            <span className='ml-2 cursor-pointer text-slate-400 hover:text-slate-500 transition-all'>
              {ICONS.closeCircle}
            </span>
          </Popconfirm>
        )}
      </div>
    </>
  );
};

export default StopWatch;
