import { Form } from 'antd';
import React from 'react';
import TagsService from 'src/common/api/TagsService';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './tags-fields';
import { useDispatch } from 'react-redux';
import { closeModal } from 'src/common/actions/modal';

const TagsAdd = ({ onSuccess }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    TagsService.create(data).then(() => {
      onSuccess();
      form.resetFields();
      dispatch(closeModal());
    });
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={mainFields}
      />
    </div>
  );
};

export default TagsAdd;
