import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ClientsService from 'src/common/api/ClientsService';
import ProjectsService from 'src/common/api/ProjectsService';
import FormFactory from 'src/components/FormFactory';
import TasksList from '../tasks/TasksList';
import { mainFields } from './project-fields';
import { store } from 'src';
import ROLES from 'src/common/enums/roles';

const ProjectsEdit = () => {
  let { id } = useParams();
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const user = store.getState().auth?.user?.data;
  const isAdmin = [ROLES.Superadmin, ROLES.Admin].includes(user?.role_id);

  useEffect(() => {
    getData(id);
  }, [id]);

  const getData = async (id) => {
    let { data } = await ProjectsService.one(id);
    form.setFieldsValue({ ...data, client_id: data.client.id });
    getClients();
  };

  const onSubmit = (data) => {
    ProjectsService.update(id, data);
  };

  const getClients = async () => {
    let response = await ClientsService.allWithoutPagination();
    setFormFields(
      mainFields({
        clientsDropdown: response.data,
        headerTitle: 'Project - Edit',
        isAdmin,
      })
    );
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={formFields}
      />

      <TasksList projectId={id} />
    </div>
  );
};

export default ProjectsEdit;
