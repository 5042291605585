import { Form, Input, Tabs, Switch, Tooltip } from 'antd';
import { HomeOutlined, TeamOutlined } from '@ant-design/icons';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import React, { useEffect, useState } from 'react';
import ProjectsService from 'src/common/api/ProjectsService';
import TasksService from 'src/common/api/TasksService';
import TimeTrackerService from 'src/common/api/TimeTrackerService';
import { ICONS } from 'src/common/Icons';
import Utils from 'src/common/Utils';
import ProjectsDropdown from './ProjectsDropdown';
import StopWatch from './StopWatch';
import TagsDropdown from './TagsDropdown';
import TasksDropdown from './TasksDropdown';
import TimeRangePicker from './TimeRangePicker';

const Tracker = ({ getAllTrackedTimes }) => {
  const [tasks, setTasks] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState(1);
  const [time, setTime] = useState(null);
  const [form] = Form.useForm();
  const [projects, setProjects] = useState(null);
  const [tags, setTags] = useState(null);
  const [isWorkFromHome, setIsWorkFromHome] = useState(false);

  useEffect(() => {
    getCurrentTracker();
    getProjects();

    const unsubscribe = Utils.observeStore(({ tags, projects }) => {
      setTags(tags.tags);
      setProjects(projects.projects);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const getTasks = async (projectId) => {
    let { data } = await TasksService.allWithoutPagination(projectId);
    setTasks(data);
  };

  const getCurrentTracker = async () => {
    let { data } = await TimeTrackerService.current();
    if (data) {
      setTime(data);
      form.setFieldsValue(data);
      getTasks(data.project_id);
    } else {
      setTime({ time_spent: 0 });
    }
  };

  const updateRunningTimer = async () => {
    const data = form.getFieldsValue();

    if (time?.time_spent > 0) {
      TimeTrackerService.updateRunningTimer(data).then((res) => {});
    }
  };

  const getProjects = async () => {
    let { data } = await ProjectsService.allWithoutPagination();

    setProjects(data);
  };

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete='off'
      >
        <main className='p-4 bg-white rounded-lg flex items-center w-full md:flex-wrap 2xl:flex-nowrap flex-wrap'>
          <div className='flex gap-4 w-full'>
            <Form.Item
              name='description'
              className='grow mb-0'
            >
              <Input
                onBlur={updateRunningTimer}
                placeholder='What are you working on?'
              />
            </Form.Item>
            <Form.Item
              name='id'
              className='hidden'
              hidden={true}
            >
              <Input />
            </Form.Item>
            <ProjectsDropdown
              onSave={updateRunningTimer}
              projects={projects}
              getTasks={getTasks}
            />
            <TasksDropdown
              onSave={updateRunningTimer}
              form={form}
              tasks={tasks}
              getTasks={getTasks}
            />
            <TagsDropdown
              onSave={updateRunningTimer}
              tags={tags}
            />
            <Form.Item
              name='is_work_from_home'
              valuePropName='checked'
              initialValue={isWorkFromHome}
              className='mb-0'
            >
              <Tooltip title={isWorkFromHome ? 'From home' : 'From office'}>
                <Switch
                  onChange={(checked) => {
                    form?.setFieldValue('is_work_from_home', checked);
                    setIsWorkFromHome(checked);
                  }}
                  style={{
                    background: isWorkFromHome ? '#B4FF00' : '#132038',
                    color: isWorkFromHome ? '#132038' : '#B4FF00',
                  }}
                  checkedChildren={<HomeOutlined className='text-[#132038]' />}
                  unCheckedChildren={
                    <TeamOutlined className='text-[#B4FF00]' />
                  }
                />
              </Tooltip>
            </Form.Item>
          </div>
          <div className='flex justify-center pt-3 pl-3 xl:pt-5 items-center w-fit xl:pl-3 md:pl-3 sm:pt-5 sm:pl-3 2xl:pl-5 2xl:pt-0'>
            <Tabs
              type='card'
              className='w-fit'
              animated={true}
              activeKey={activeTabKey}
              renderTabBar={(data) => null}
              items={[
                {
                  label: ICONS.timer,
                  key: 1,
                  children: (
                    <>
                      {time && (
                        <ErrorBoundary>
                          <StopWatch
                            currentTime={time?.time_spent}
                            getAllTrackedTimes={getAllTrackedTimes}
                            form={form}
                            setCurrentRunningTime={setTime}
                            setIsWorkFromHome={setIsWorkFromHome}
                          />
                        </ErrorBoundary>
                      )}
                    </>
                  ),
                },
                {
                  label: ICONS.timer,
                  key: 2,
                  children: (
                    <ErrorBoundary>
                      <Form.Item
                        name='id'
                        className='flex gap-4 !w-full justify-center items-center m-0'
                      >
                        <TimeRangePicker
                          form={form}
                          setIsWorkFromHome={setIsWorkFromHome}
                        />
                      </Form.Item>
                    </ErrorBoundary>
                  ),
                },
              ]}
            />
            <div className='flex flex-col items-center justify-center ml-3 text-slate-400'>
              <span
                className={`cursor-pointer ${
                  activeTabKey === 1 && 'text-blue-500'
                }`}
                onClick={() => setActiveTabKey(1)}
              >
                {ICONS.timer}
              </span>
              <span
                className={`cursor-pointer ${
                  activeTabKey === 2 && 'text-blue-500'
                }`}
                onClick={() => setActiveTabKey(2)}
              >
                {ICONS.timer}
              </span>
            </div>
          </div>
        </main>
      </Form>
    </>
  );
};

export default Tracker;
