import { store } from 'src';
import { startLoader, stopLoader } from 'src/common/actions/loader';
import config from 'src/config/config';
import { axios } from '../../config/axios';

/**
 * Factory function for creating API service objects that interact with a specified endpoint.
 *
 * @param {object} options - Configuration options for the API service.
 * @param {string} options.endpoint - The API endpoint to interact with.
 * @returns {object} An API service object with methods for common CRUD operations.
 */
const ApiFactory = ({ endpoint }) => {
  return {
    /**
     * Retrieves a list of items from the API with pagination support.
     *
     * @async
     * @param {object} params - Parameters for the API request.
     * @param {number} params.page - The page number to retrieve.
     * @param {string} params.query - Additional query parameters.
     * @returns {Promise} A Promise that resolves to the API response.
     */
    async all({ page, query }) {
      let response;
      try {
        response = await axios.get(`/${endpoint}?per_page=10${query || ''}`);
      } catch (err) {
        if (!config.production) console.log('ERROR', err);
      }
      return response;
    },

    /**
     * Retrieves a list of all items from the API without pagination.
     *
     * @returns
     */
    async allWithoutPagination() {
      let response;
      try {
        response = await axios.get(`/${endpoint}/all`);
      } catch (err) {
        if (!config.production) console.log('ERROR', err);
      }
      return response;
    },
    async one(id) {
      let response;
      store.dispatch(startLoader());
      try {
        response = await axios.get(`/${endpoint}/${id}`);
        store.dispatch(stopLoader());
      } catch (err) {
        if (!config.production) console.log('ERROR', err);
        store.dispatch(stopLoader());
      }
      return response;
    },
    async delete(id) {
      let response;
      store.dispatch(startLoader());
      try {
        response = await axios.delete(`/${endpoint}/${id}`);
        store.dispatch(stopLoader());
      } catch (err) {
        if (!config.production) console.log('ERROR', err);
        store.dispatch(stopLoader());
      }
      return response;
    },
    async update(id, data) {
      let response;
      store.dispatch(startLoader());
      try {
        response = await axios.patch(`/${endpoint}/${id}`, data);
        store.dispatch(stopLoader());
      } catch (err) {
        if (!config.production) console.log('ERROR', err);
        store.dispatch(stopLoader());
      }
      return response;
    },
    async create(data) {
      let response;
      store.dispatch(startLoader());
      try {
        response = await axios.post(`/${endpoint}`, data);
        store.dispatch(stopLoader());
      } catch (err) {
        if (!config.production) console.log('ERROR', err);
        store.dispatch(stopLoader());
      }
      return response;
    },
  };
};

export default ApiFactory;
