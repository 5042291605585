import { Form } from 'antd';
import React, { useEffect } from 'react';
import TagsService from 'src/common/api/TagsService';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './tags-fields';
import { useDispatch } from 'react-redux';
import { closeModal } from 'src/common/actions/modal';

const TagsEdit = ({ id, onSuccess }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    getData(id);
  }, [id]);

  const getData = async (id) => {
    let { data } = await TagsService.one(id);
    form.setFieldsValue(data);
  };

  const onSubmit = (data) => {
    TagsService.update(id, data).then(() => {
      onSuccess();
      dispatch(closeModal());
    });
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={mainFields}
      />
    </div>
  );
};

export default TagsEdit;
