import ROLES from 'src/common/enums/roles';
import ClientsEdit from 'src/pages/clients/ClientsEdit';
import ClientsList from 'src/pages/clients/ClientsList';
import MaintenancesEditPostpaid from 'src/pages/clients/maintenances/MaintenancesEditPostpaid';
import MaintenancesEditPrepaid from 'src/pages/clients/maintenances/MaintenancesEditPrepaid';
import Dashboard from 'src/pages/dashboard/Dashboard';
import KpiAdd from 'src/pages/kpi/KpiAdd';
import KpiEdit from 'src/pages/kpi/KpiEdit';
import KpiList from 'src/pages/kpi/KpiList';
import KpiTemplatesAdd from 'src/pages/kpi/kpi-templates/KpiTemplatesAdd';
import KpiTemplatesEdit from 'src/pages/kpi/kpi-templates/KpiTemplatesEdit';
import KpiTemplatesList from 'src/pages/kpi/kpi-templates/KpiTemplatesList';
import KpiUserList from 'src/pages/kpi/kpi-user/KpiUserList';
import LicensesList from 'src/pages/licenses/LicensesList';
import ProfileEdit from 'src/pages/profile/ProfileEdit';
import ProjectsEdit from 'src/pages/projects/ProjectsEdit';
import ProjectsList from 'src/pages/projects/ProjectsList';
import TagsList from 'src/pages/tags/TagsList';
import TimeTracker from 'src/pages/time-tracker/TimeTracker';
import UsersList from 'src/pages/users/UsersList';

const ROUTES = [
  {
    path: '/dashboard',
    allowedRoles: ROLES.all(),
    component: <Dashboard />,
  },
  {
    path: '/tracker',
    allowedRoles: ROLES.all(),
    component: <TimeTracker />,
  },
  // * PROJECTS
  {
    path: '/projects',
    allowedRoles: ROLES.all(),
    component: <ProjectsList />,
  },
  {
    path: '/projects/edit/:id',
    allowedRoles: ROLES.all(),
    component: <ProjectsEdit />,
  },
  // * CLIENTS
  {
    path: '/clients',
    allowedRoles: [ROLES.Superadmin, ROLES.Admin],
    component: <ClientsList />,
  },
  {
    path: '/clients/edit/:id',
    allowedRoles: [ROLES.Superadmin, ROLES.Admin],
    component: <ClientsEdit />,
  },
  {
    path: '/clients/edit/:id/postpaid/:maintenanceId',
    allowedRoles: [ROLES.Superadmin, ROLES.Admin],
    component: <MaintenancesEditPostpaid />,
  },
  {
    path: '/clients/edit/:id/prepaid/:maintenanceId',
    allowedRoles: [ROLES.Superadmin, ROLES.Admin],
    component: <MaintenancesEditPrepaid />,
  },
  // * TAGS
  {
    path: '/tags',
    allowedRoles: [ROLES.Superadmin, ROLES.Admin],
    component: <TagsList />,
  },
  // * LICENSES
  {
    path: '/licenses',
    allowedRoles: [ROLES.Superadmin, ROLES.Admin],
    component: <LicensesList />,
  },
  // * USERS
  {
    path: '/users',
    allowedRoles: ROLES.all(),
    component: <UsersList />,
  },
  // * PROFILE
  {
    path: '/profile',
    allowedRoles: ROLES.all(),
    component: <ProfileEdit />,
  },
  // * KPI
  {
    path: '/kpi/users',
    allowedRoles: ROLES.all(),
    component: <KpiUserList />,
  },
  {
    path: '/kpi/:id',
    allowedRoles: ROLES.all(),
    component: <KpiEdit />,
  },
  {
    path: '/kpi/create',
    allowedRoles: [ROLES.Superadmin, ROLES.Admin],
    component: <KpiAdd />,
  },
  {
    path: '/kpi/users/:id',
    allowedRoles: ROLES.all(),
    component: <KpiList />,
  },
  // * KPI TEMPLATES
  {
    path: '/kpi/templates',
    allowedRoles: [ROLES.Superadmin, ROLES.Admin],
    component: <KpiTemplatesList />,
  },
  {
    path: '/kpi/templates/create',
    allowedRoles: [ROLES.Superadmin, ROLES.Admin],
    component: <KpiTemplatesAdd />,
  },
  {
    path: '/kpi/templates/edit/:id',
    allowedRoles: [ROLES.Superadmin, ROLES.Admin],
    component: <KpiTemplatesEdit />,
  },
];

export default ROUTES;
