import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import MaintenancesService from 'src/common/api/MaintenancesService';
import FormFactory from 'src/components/FormFactory';
import { editPrepaidFields } from './maintenances-fields';
import MaintenancesTimes from './MaintenancesTimes';

const MaintenancesEditPrepaid = () => {
  let { maintenanceId } = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    getData(maintenanceId);
  }, [maintenanceId]);

  const getData = async (id) => {
    let { data } = await MaintenancesService.one(id);
    form.setFieldsValue(data);
  };

  const onSubmit = (data) => {
    MaintenancesService.update(maintenanceId, data);
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={editPrepaidFields()}
      />

      {maintenanceId && (
        <MaintenancesTimes
          switchLabel='Package included'
          maintenanceId={maintenanceId}
        />
      )}
    </div>
  );
};

export default MaintenancesEditPrepaid;
