import LOADER from 'src/common/enums/loader';

export const LOADER_START = 'LOADER_START';
export const LOADER_STOP = 'LOADER_STOP';

export const startLoader = () => {
  return {
    type: LOADER_START,
    loading: true,
    completed: LOADER.Unactive,
  };
};

export const stopLoader = (completed = LOADER.Unactive) => {
  return {
    type: LOADER_STOP,
    loading: false,
    completed: completed,
  };
};
