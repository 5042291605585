import Utils from "src/common/Utils";

export const barChartOptions = {
  responsive: true,
  scales: {
    y: {
      ticks: {
        stepSize: 3600,
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          const niz = {
            3600: "1h",
            7200: "2h",
            10800: "3h",
            14400: "4h",
            18000: "5h",
            21600: "6h",
            25200: "7h",
            28800: "8h",
            32400: "9h",
            36000: "10h",
            39600: "11h",
            43200: "12h",
            46800: "13h",
          };
          return `${niz[value] || ""}`;
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Report by days",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const time = Utils.secondsToTime(context.raw).obj;
          return `${time.h}:${time.m}h`;
        },
      },
    },
  },
};

export const pieChartOptions = {
  responsive: true,
  scales: {
    y: {
      ticks: {
        stepSize: 3600,
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          const niz = {
            3600: "1h",
            7200: "2h",
            10800: "3h",
            14400: "4h",
            18000: "5h",
            21600: "6h",
            25200: "7h",
            28800: "8h",
            32400: "9h",
            36000: "10h",
            39600: "11h",
            43200: "12h",
            46800: "13h",
          };
          return `${niz[value] || ""}`;
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: true,
      text: "Report by projects",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const time = Utils.secondsToTime(context.raw).obj;
          return `${time.h}:${time.m}h`;
        },
      },
    },
  },
};
