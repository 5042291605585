import { Form, Select } from 'antd';
import React, { useEffect } from 'react';
const { Option } = Select;
const TagsDropdown = ({ tags, onSave }) => {
  useEffect(() => {}, [tags]);

  return (
    <Form.Item
      name='tag_id'
      className='w-[110px] mb-0'
    >
      <Select
        onSelect={() => {
          if (onSave) onSave();
        }}
        placeholder='Tags'
        dropdownMatchSelectWidth={false}
        optionFilterProp='children'
        showSearch={true}
      >
        {tags &&
          tags?.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};

export default TagsDropdown;
